import {useMutation, useQueryClient} from '@tanstack/react-query'
import {GOAL_API} from '../services/goal.http'
import {QUERY_KEYS} from './keys'
import {SendGiftStatusesE} from '@/features/gift/confirm-send-gift-modal/store/createConfirmSendGiftModalSlice'
import {QUERY_KEYS as CHAT_QUERY_KEYS} from '@/features/chat/queries/keys'

export const useDonateGoal = (goalId: string | number, onSuccess?: (status: SendGiftStatusesE) => void) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({hostID, amount}: {hostID?: string; amount?: number}) =>
            GOAL_API.donateGoal(`${hostID}`, `${goalId}`, +`${amount}`),
        onSuccess: () => {
            onSuccess?.(SendGiftStatusesE.Success)
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.selected_goal]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.goals_list]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.saved_goals]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.supported_goals]})
            queryClient.invalidateQueries({queryKey: [CHAT_QUERY_KEYS.fetchChannels]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.host_goals]})
        }
    })
}
