import {Avatar} from '@/components/ui/avatar/Avatar'
import {FeaturedRookieType} from '@/features/authentication/types'
import {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {ToolTipActionsModal} from '../host-dashboard/actions-section/components/toolTipActionsModal/ToolTipActionsModal'
import {
    StyledHostProfileTaxiSectionContainer,
    StyledHostProfileTaxiSectionHeader,
    StyledHostProfileTaxiSectionHeaderTitle,
    StyledHostProfileTaxiSectionList,
    StyledInfoIcon,
    StyledTaxiUser,
    StyledTaxiUserCTA,
    StyledTaxiUserFullName
} from './style'

type HostProfileTaxiSectionProps = {featuredRookie: FeaturedRookieType[]}

const HostProfileTaxiSection: FC<HostProfileTaxiSectionProps> = ({featuredRookie}) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    // const {breakpoints} = useTheme()
    // const {width} = useWindowDimensions()
    // const [isMobile, setIsMobile] = useState(width <= breakpoints.width.m)
    const [openToolTipModal, setOpenToolTipModal] = useState(false)

    // useEffect(() => {
    //     setIsMobile(width <= breakpoints.width.m)
    // }, [width])

    return (
        <>
            <StyledHostProfileTaxiSectionContainer>
                <StyledHostProfileTaxiSectionHeader>
                    <StyledHostProfileTaxiSectionHeaderTitle>
                        {t('common:featured_hosts')}
                    </StyledHostProfileTaxiSectionHeaderTitle>
                    {/* <StyledInfoIcon
                        fill="#00063D80"
                        width={isMobile ? 24 : 30}
                        height={isMobile ? 24 : 30}
                        onClick={() => {
                            setOpenToolTipModal(true)
                        }}
                    /> */}
                </StyledHostProfileTaxiSectionHeader>
                <StyledHostProfileTaxiSectionList>
                    {featuredRookie.map(user => (
                        <StyledTaxiUser
                            key={user.id}
                            onClick={() => {
                                navigate(`/${user.username}`)
                            }}
                        >
                            <Avatar
                                height={60}
                                width={60}
                                imageUrl={user.photos?.find(photo => photo.main)?.url as string}
                            />
                            <StyledTaxiUserFullName>{user.full_name}</StyledTaxiUserFullName>
                            <StyledTaxiUserCTA variant="outlined">{t('common:see_profile')}</StyledTaxiUserCTA>
                        </StyledTaxiUser>
                    ))}
                </StyledHostProfileTaxiSectionList>
            </StyledHostProfileTaxiSectionContainer>
            {openToolTipModal && (
                <ToolTipActionsModal
                    content={{
                        title: '...',
                        content: '...',
                        icon: () => <StyledInfoIcon fill="#00063D80" width={70} height={70} />
                    }}
                    onClose={() => {
                        setOpenToolTipModal(false)
                    }}
                />
            )}
        </>
    )
}
export default HostProfileTaxiSection
