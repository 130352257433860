import {Trans, useTranslation} from 'react-i18next'
import {Modal} from '@/components/ui/modal/Modal'
import newSurprises from '@assets/images/my-surprises/new_surprises.png'
import mySurprises from '@assets/images/my-surprises/mySurprises.png'
import {StyledButton, StyledSubTitle, StyledTitle, StyledWrapper, modalStyles} from './style'
import {useGetConnectedUser} from '@/hooks/useGetConnectedUser'
import {SubscriptionStatusesE} from '@/features/chat/helpers/helpers'
import {useEffect, useMemo, useState} from 'react'
import {selectOpenSurprisesModal, selectRecurringGiftModal} from '@/store/selectors'
import {useRootStore} from '@/store'
import {useTheme} from 'styled-components'
import {SurpriseMediaSection} from '../surprise-media-section/SurpriseMediaSection'
import {useUnlockHostSurprise} from '../../queries/useUnlockHostSurprise'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS as HOST_QUERY_KEYS} from '@/features/guest/queries/keys'
import {QUERY_KEYS as MY_MOMENTS_QUERY_KEYS} from '@/features/my-moments/queries/keys'
import {QUERY_KEYS, MUTATION_KEYS} from '../../queries/keys'
import {useGetHost} from '@/features/guest/queries/useGetHost'
import {useHandleError} from '@/hooks/useHandleError'
import {useUnlockHostSurpriseById} from '../../queries/useUnlockHostSurpriseById'

export const modalData = {
    not_supported: {
        title: 'rookie_profile_preview:host_surprises_modal:subtitle',
        subTitle: null,
        description: 'rookie_profile_preview:host_surprises_modal:guest_need_be_supporter_text',
        buttonText: 'rookie_profile_preview:host_surprises_modal:supporting_button',
        buttonWithImage: false
    },
    supported_no_surprises: {
        title: 'rookie_profile_preview:host_surprises_modal:subtitle',
        subTitle: null,
        description: 'rookie_profile_preview:host_surprises_modal:guest_have_open_text',
        buttonText: 'rookie_profile_preview:host_surprises_modal:upgrade_button',
        buttonWithImage: false
    },
    supported_has_surprises: {
        title: null,
        subTitle: 'rookie_profile_preview:host_surprises_modal:guest_can_open_text',
        description: null,
        buttonText: 'rookie_profile_preview:host_surprises:button',
        buttonWithImage: true
    },
    supported_host_no_surprises: {
        title: 'rookie_profile_preview:host_surprises_modal:subtitle',
        subTitle: null,
        description: null,
        buttonText: 'rookie_profile_preview:host_surprises_modal:upgrade_button',
        buttonWithImage: false
    }
}

export const OpenSurprisesModal = () => {
    const {closeModal, hostId, hostUserName, surpriseId, onSuccess} = useRootStore(selectOpenSurprisesModal)
    const {t} = useTranslation()
    const {palette} = useTheme()
    const queryClient = useQueryClient()
    const {connectedUser: connectedHost} = useGetConnectedUser(Number(hostId))
    const {data: hostData} = useGetHost({filter: hostUserName ?? undefined, isUsername: true})
    const maxNumberOfAllowedSurprises = hostData?.type_attributes?.subscription_package?.surprises_count ?? 0
    const guestSurprisesOpened = hostData?.type_attributes?.leader_surprises_seen_count ?? 0
    // const hostSurprisesCount = hostData?.type_attributes?.surprises_count ?? 0
    const guestSurprisesLeftToOpen = maxNumberOfAllowedSurprises - guestSurprisesOpened
    // hostSurprisesCount < maxNumberOfAllowedSurprises
    //     ? hostSurprisesCount - guestSurprisesOpened
    //     : maxNumberOfAllowedSurprises - guestSurprisesOpened
    const isGuestMonthlySupporter =
        !!connectedHost && connectedHost?.subscription_status === SubscriptionStatusesE.ACTIVE
    const guestHasSurprisesToOpen = guestSurprisesLeftToOpen > 0
    // const hostHasSurprisesEnoughToOpen = hostSurprisesCount >= maxNumberOfAllowedSurprises
    const {openModal: openGiftModal} = useRootStore(selectRecurringGiftModal)

    const [isShowMedia, setIsShowMedia] = useState(false)
    const [isShowSurprise, setIsShowSurprise] = useState(false)
    const {
        data: surpriseRandom,
        isSuccess,
        isLoading,
        isError,
        error
    } = useUnlockHostSurprise({hostId: String(hostId), enabled: isShowMedia && !surpriseId})

    const {
        data: surpriseById,
        mutate,
        isSuccess: isSuccessById,
        isPending: isLoadingById,
        isError: isErrorById,
        error: errorById
    } = useUnlockHostSurpriseById({hostId: String(hostId)})

    useHandleError({isError: isErrorById || isError, error: errorById || error})

    useEffect(() => {
        if ((!!surpriseRandom && isSuccess) || (surpriseById && isSuccessById)) {
            queryClient.invalidateQueries({queryKey: [HOST_QUERY_KEYS.host]})
            queryClient.invalidateQueries({queryKey: [MY_MOMENTS_QUERY_KEYS.my_moments]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.host_surprises]})
        }
    }, [isSuccess, isSuccessById])

    const correctModalData = useMemo(() => {
        switch (true) {
            // case isGuestMonthlySupporter && !guestHasSurprisesToOpen && !hostHasSurprisesEnoughToOpen:
            //     return modalData.supported_host_no_surprises
            case isGuestMonthlySupporter && !guestHasSurprisesToOpen:
                return modalData.supported_no_surprises
            case isGuestMonthlySupporter && guestHasSurprisesToOpen:
                return modalData.supported_has_surprises
            case !isGuestMonthlySupporter:
            default:
                return modalData.not_supported
        }
    }, [isGuestMonthlySupporter, guestHasSurprisesToOpen])

    return (
        <Modal
            onClose={closeModal}
            fullScreen
            width={'400px'}
            modalStyles={modalStyles}
            closeIconColor={palette.neutral.white}
            hideCloseButton={isShowSurprise && !isLoading && !isError}
        >
            {isShowMedia ? (
                <SurpriseMediaSection
                    surprise={surpriseRandom ?? surpriseById}
                    onClose={() => {
                        setIsShowMedia(false)
                        setIsShowSurprise(false)
                        queryClient.removeQueries({queryKey: [QUERY_KEYS.unlock_host_surprise]})
                        queryClient.removeQueries({queryKey: [MUTATION_KEYS.unlock_host_surprise_by_id]})
                        onSuccess?.()
                        closeModal()
                    }}
                    isShowSurprise={isShowSurprise}
                    setIsShowSurprise={setIsShowSurprise}
                    isLoading={isLoading || isLoadingById}
                />
            ) : (
                <StyledWrapper>
                    {!!correctModalData.title && <StyledTitle>{t(correctModalData.title)}</StyledTitle>}

                    <img src={newSurprises} />

                    {correctModalData.subTitle && (
                        <StyledTitle>
                            <Trans
                                t={t}
                                i18nKey={correctModalData.subTitle}
                                components={{
                                    span: <span />
                                }}
                                values={{GUEST_CAN_SURPRISES_OPEN: guestSurprisesLeftToOpen}}
                            />
                        </StyledTitle>
                    )}

                    {correctModalData.description && <StyledSubTitle>{t(correctModalData.description)}</StyledSubTitle>}

                    <StyledButton
                        onClick={() => {
                            if (isGuestMonthlySupporter && guestHasSurprisesToOpen) {
                                surpriseId && mutate(surpriseId)
                                setIsShowMedia(true)
                            } else {
                                openGiftModal({
                                    hostID: `${hostId}`
                                })
                                closeModal()
                            }
                        }}
                    >
                        {correctModalData.buttonWithImage && <img src={mySurprises} />}
                        {t(correctModalData.buttonText)}
                        {correctModalData.buttonWithImage && <img src={mySurprises} />}
                    </StyledButton>
                </StyledWrapper>
            )}
        </Modal>
    )
}
