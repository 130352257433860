import {Avatar} from '@/components/ui/avatar/Avatar'
import {StyledDate, StyledInfo, StyledInfoWrapper} from './style'
import mySurprises from '@assets/images/my-surprises/mySurprises.png'

export const HostInfo = ({
    hostAvatar,
    hostName,
    date,
    isSurprise
}: {
    hostAvatar?: string
    hostName?: string
    date: string
    isSurprise: boolean
}) => {
    return (
        <StyledInfoWrapper>
            <Avatar imageUrl={hostAvatar ?? ''} width={46} height={46} name={hostName} />

            <StyledInfo>
                <div>{hostName}</div>
                <StyledDate>
                    <span>{date}</span>
                    {isSurprise && <img src={mySurprises} />}
                </StyledDate>
            </StyledInfo>
        </StyledInfoWrapper>
    )
}
HostInfo.displayName = 'HostInfo'
