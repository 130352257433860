import {LockIcon, MediaPackagePhotoIcon, MediaPackageVideoIcon, MicromorgiIcon} from '@/assets/icons/icons'
import {Avatar} from '@/components/ui/avatar/Avatar'
import {Button} from '@/components/ui/button/Button'
import {MediaLightBox} from '@/components/ui/media-light-box/MediaLightBox'
import {getLocalDate} from '@/dayjs'
import {HostPartialType} from '@/features/authentication/types'
import {HostInfo} from '@/features/my-moments/components/moment-item/host-info/HostInfo'
import {
    StyledContentWrapper,
    StyledDate,
    StyledFullNameContainer,
    StyledHost,
    StyledInfoWrapper,
    StyledWrapper
} from '@/features/my-moments/components/moment-item/style'
import {MURAL_TYPES} from '@/features/profile/services/completeProfile.schema'
import {MuralType} from '@/features/profile/services/types'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {useRootStore} from '@/store'
import {selectBuyMorgisModal, selectOpenSurprisesModal, selectRecurringGiftModal} from '@/store/selectors'
import {checkMediaExtension} from '@/utilities/helpers'
import {FC, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import HostProfileMuralPreview from '../host-profile-mural-preview/HostProfileMuralPreview'
import {StyledCounterItem, StyledCounterItemsContainer, StyledPackageName, StyledUnpaidContentWrapper} from './style'
import {useUnlockMediaPackage} from '@/features/mediaPackages/queries/useUnlockMediaPackage'

type HostProfileMuralItemProps = {
    item: MuralType
    host: HostPartialType
    refetchMuralList: () => void
    defaultItem?: {url: string; muralType: MURAL_TYPES}
    guestsBalance?: number
}
const HostProfileMuralItem: FC<HostProfileMuralItemProps> = ({
    item,
    host,
    refetchMuralList,
    defaultItem,
    guestsBalance
}) => {
    const {openModal: openSurprisesModal} = useRootStore(selectOpenSurprisesModal)
    const {openModal: openGiftModal} = useRootStore(selectRecurringGiftModal)
    const {openModal: openModalToBuyMicromorgi} = useRootStore(selectBuyMorgisModal)
    const {t} = useTranslation()
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)
    const {mural_type: muralType, type, created_at, url, blurred_url, media_packages_contents} = item
    const isMediaPackage = muralType === MURAL_TYPES.MEDIA_PACKAGES
    const isPaid = isMediaPackage && media_packages_contents ? !!media_packages_contents[0]?.url : !!url
    const formattedDate = getLocalDate(created_at, 'MMM D, YYYY')
    const openSubscribeModal = () => {
        openGiftModal({hostID: String(host.id)})
    }
    const {breakpoints} = useTheme()
    const {width} = useWindowDimensions()
    const [isMobile, setIsMobile] = useState(width <= breakpoints.width.m)
    const {mutate: unlockMediaPackage} = useUnlockMediaPackage(refetchMuralList)
    const [currentMediaPackageIndex, setCurrentMediaPackageIndex] = useState(0)
    const onChangeCurrentMediaPackageIndex = (currentIndex: number) => {
        if (isMediaPackage) setCurrentMediaPackageIndex(currentIndex)
    }
    useEffect(() => {
        setIsMobile(width <= breakpoints.width.m)
    }, [width])
    const onClickUnlockMediaPackage = () => {
        if (typeof guestsBalance === 'number' && !!item.amount) {
            if (item.amount > guestsBalance) openModalToBuyMicromorgi({})
            else unlockMediaPackage({hostId: host.id as number, mediaPackageId: item.id})
        }
    }
    const mediaMap = [
        ...new Set(
            (isMediaPackage && media_packages_contents
                ? media_packages_contents.map(content => content.url || content.blurred_url)
                : [url ?? blurred_url]
            ).map(m => checkMediaExtension(m ?? ''))
        )
    ]
    const UNPAID_CONTENT = {
        [MURAL_TYPES.MEDIA_PACKAGES]: () => {
            const imagesCounter = item.media_packages_contents_counts?.image || 0
            const videosCounter = item.media_packages_contents_counts?.video || 0
            return (
                <StyledUnpaidContentWrapper>
                    <LockIcon width={isMobile ? 105 : 180} height={isMobile ? 105 : 180} />
                    <StyledPackageName>{item.name}</StyledPackageName>
                    <StyledCounterItemsContainer>
                        {imagesCounter > 0 && (
                            <StyledCounterItem>
                                {imagesCounter}
                                <MediaPackagePhotoIcon />
                            </StyledCounterItem>
                        )}
                        {videosCounter > 0 && (
                            <StyledCounterItem>
                                {videosCounter}
                                <MediaPackageVideoIcon />
                            </StyledCounterItem>
                        )}
                    </StyledCounterItemsContainer>
                    <StyledCounterItemsContainer>
                        <MicromorgiIcon height={isMobile ? 20 : 32} width={isMobile ? 20 : 32} />
                        {item.amount}
                    </StyledCounterItemsContainer>
                </StyledUnpaidContentWrapper>
            )
        },
        [MURAL_TYPES.PHOTOS]: () => (
            <StyledUnpaidContentWrapper>
                <LockIcon />
                <Button onClick={openSubscribeModal}>
                    {t('pages:rookie_profile_page:subscribe_for_content', {NAME: host.type_attributes?.first_name})}
                </Button>
            </StyledUnpaidContentWrapper>
        ),
        [MURAL_TYPES.VIDEOS]: () => (
            <StyledUnpaidContentWrapper>
                <LockIcon />
                <Button onClick={openSubscribeModal}>
                    {t('pages:rookie_profile_page:subscribe_for_content', {NAME: host.type_attributes?.first_name})}
                </Button>
            </StyledUnpaidContentWrapper>
        ),
        [MURAL_TYPES.SURPRISES]: () => (
            <StyledUnpaidContentWrapper>
                <LockIcon />
                <Button
                    onClick={() => {
                        openSurprisesModal({
                            hostId: host.id as number,
                            hostUserName: String(host.username),
                            surpriseId: item.id,
                            onSuccess: refetchMuralList
                        })
                    }}
                >
                    {t('pages:rookie_profile_page:open_surprises', {NAME: host.type_attributes?.first_name})}
                </Button>
            </StyledUnpaidContentWrapper>
        )
    }[muralType]

    return (
        <>
            <StyledWrapper>
                <StyledInfoWrapper isMediaPackage={!!media_packages_contents}>
                    <StyledHost>
                        <StyledFullNameContainer>
                            <Avatar
                                imageUrl={host.avatar?.url}
                                width={54}
                                height={54}
                                name={host.full_name}
                                initialFontSize={12}
                            />
                            <div>
                                <p>{host.full_name}</p>
                                <StyledDate>{formattedDate}</StyledDate>
                            </div>
                        </StyledFullNameContainer>
                    </StyledHost>

                    <StyledContentWrapper onClick={isPaid ? () => setIsPreviewOpen(true) : undefined}>
                        <HostProfileMuralPreview
                            muralType={muralType}
                            type={type}
                            url={url ?? blurred_url}
                            isPaid={isPaid}
                            extraUnpaidContent={UNPAID_CONTENT}
                            defaultItem={defaultItem}
                            mediaPackagesContents={isMediaPackage ? media_packages_contents : undefined}
                            onChangeCurrentMediaPackageIndex={
                                isMediaPackage ? onChangeCurrentMediaPackageIndex : undefined
                            }
                        />
                    </StyledContentWrapper>
                    {!isPaid && muralType === MURAL_TYPES.MEDIA_PACKAGES && (
                        <Button onClick={onClickUnlockMediaPackage}>
                            {t('pages:rookie_profile_page:unlock_media_package')}
                        </Button>
                    )}
                </StyledInfoWrapper>
            </StyledWrapper>

            {isPreviewOpen && (
                <MediaLightBox
                    currentIndex={isMediaPackage ? currentMediaPackageIndex : undefined}
                    media={mediaMap}
                    onClose={() => setIsPreviewOpen(false)}
                    headerInfo={
                        <HostInfo
                            date={formattedDate}
                            hostName={host?.full_name}
                            hostAvatar={host?.avatar?.url}
                            isSurprise={muralType === MURAL_TYPES.SURPRISES}
                        />
                    }
                />
            )}
        </>
    )
}
export default HostProfileMuralItem
