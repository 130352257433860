import {HeartIcon, HeartIconFilled} from '@/assets/icons/icons'
import {Spinner} from '@/components/ui/spinner/Spinner'
import dayjs, {UnitsE} from '@/dayjs'
import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType, HostType} from '@/features/authentication/types'
import {SubscriptionStatusesE} from '@/features/chat/helpers/helpers'
import {useGetHost} from '@/features/guest/queries/useGetHost'
import {useGetSavedHosts} from '@/features/guest/queries/useGetSavedHosts'
import {useSaveHost} from '@/features/guest/queries/useSaveHost'
import {useUnsaveHost} from '@/features/guest/queries/useUnsaveHost'
import {useGetConnectedUser} from '@/hooks/useGetConnectedUser'
import {useHandleError} from '@/hooks/useHandleError'
import {useRootStore} from '@/store'
import {selectCancelRecurringGiftModal, selectChatFavTopics} from '@/store/selectors'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {FC, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {commonTopicsNames, getListToShow} from '../../helpers/helpers'
import BlockHostButton from '../block-host-button/BlockHostButton'
import {ConnectNowButton} from '../cta-bar/CtaBar'
import {StyledActionBtn, StyledActionBtnWrapper} from '../host-profile-cta-section/style'
import HostProfileGoalSection from '../host-profile-goal-section/HostProfileGoalSection'
import HostProfileMuralSection from '../host-profile-mural-section/HostProfileMuralSection'
import {MemberShipPlans} from '../membersip-plans/MemberShipPlans'
import {
    ContainerRow,
    StyledAvatar,
    StyledAvatarWrapper,
    StyledConnectContainer,
    StyledContainer,
    StyledFavoriteItem,
    StyledFullName,
    StyledGoBackButtonIcon,
    StyledHeartButton,
    StyledHeartSection,
    StyledHostProfileWrapper,
    StyledInformSection,
    StyledInformSectionRow,
    StyledInformSectionRowContent,
    StyledInformSectionRowTitle,
    StyledShareButton,
    StyledStickContainer
} from './style'
import {ShareProfileModal} from '@/features/host/components/share-profile-modal/ShareProfileModal'
import {GenderKeyNameE} from '@/features/constants/constants'

type HostProfileProps = {
    hostUserName?: string
    isModal?: boolean
    closeModal?: () => void
}

export const HostProfile: FC<HostProfileProps> = ({hostUserName, isModal = false, closeModal}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const topics = useRootStore(selectChatFavTopics)
    const {openModal: openCancelRecurringGiftModal} = useRootStore(selectCancelRecurringGiftModal)
    // user
    const {data: user, isFetching: isLoadingMe} = useMe()
    const guestProfile = user as GuestType
    const isGuest = user?.type === USER_ROLE_GUEST
    const {
        data: hostByUsername,
        isFetching: isLoadingHost,
        refetch: refetchHost,
        isError,
        error
    } = useGetHost({filter: hostUserName, isUsername: true})

    const {mutate: saveHost, isPending: isPendingSave} = useSaveHost(refetchHost)
    const {mutate: unsaveHost, isPending: isPendingUnsave} = useUnsaveHost(refetchHost)
    const isLoadingLike = isPendingUnsave || isPendingSave

    const likesCounter = hostByUsername?.type_attributes?.saved_count ? hostByUsername.type_attributes.saved_count : 0
    const {data: savedHosts, isLoading: isLoadingSavedHosts} = useGetSavedHosts(isGuest && !!hostByUsername)
    const isLiked = !!savedHosts?.find(host => host.id === hostByUsername?.id)

    const onClickLikeButton = () => {
        if (isLoadingLike || !hostByUsername?.id) return
        if (isLiked) unsaveHost(hostByUsername.id)
        else saveHost(hostByUsername.id)
    }
    const sizeOfHeart = 20
    const hostData = isGuest ? (hostByUsername as HostType) : (user as HostType)
    const {connectedUser: connectedHost} = useGetConnectedUser(hostData?.id)
    const isLoading = isLoadingMe || isLoadingHost || isLoadingSavedHosts
    const hasPastGoals = hostData?.type_attributes?.has_past_goals
    const hostsWish = hostData?.type_attributes?.path
    const chatTopics = getListToShow(hostData?.chat_topics, user?.chat_topics)
    const favoriteActivities = getListToShow(hostData?.favorite_activities, user?.favorite_activities)
    const onGoBackHandler = () => {
        if (isModal) closeModal?.()
        else navigate(-1)
    }
    useHandleError({isError, error, isBlankPage: true})

    // mixpanel
    const hostChatTopics = hostData?.chat_topics?.length ? hostData?.chat_topics : []
    const hostActivities = hostData?.favorite_activities?.length ? hostData?.favorite_activities : []
    const guestChatTopics = guestProfile?.chat_topics
    const guestActivities = guestProfile?.favorite_activities
    const activeFilters = guestProfile.type_attributes.leaders_carousels_filters
    const countryFilters = activeFilters
        ? guestProfile.type_attributes.leaders_carousels_filters?.filters.countries.map(country => country.name)
        : []
    const [endTime, setEndTime] = useState<dayjs.Dayjs>(dayjs())
    const handleSetEndTime = () => {
        const endTime = dayjs()
        setEndTime(endTime)
    }
    const startTime = dayjs()

    const mixpanelData = {
        carousel_card_number: 0,
        click_share: false,
        common_activities: commonTopicsNames(guestChatTopics, hostChatTopics),
        common_chat_topics: commonTopicsNames(guestActivities, hostActivities),
        country_filter_applied: countryFilters?.length ? countryFilters : 'No',
        from: 'Profile',
        morgi_balance: guestProfile.type_attributes.micro_morgi_balance,
        scrolled_images: 0,
        time_on_profile: startTime.diff(endTime, UnitsE.MILLISECOND),
        watched_video: false
    }

    const hostGender = useMemo(() => {
        switch (hostData?.gender?.id) {
            case 1:
                return GenderKeyNameE.MALE
            case 2:
                return GenderKeyNameE.FEMALE
            default:
                return GenderKeyNameE.OTHER
        }
    }, [hostData])

    const [isOpenShareProfileModal, setIsOpenShareProfileModal] = useState(false)

    const onShareClick = () => {
        // setIsClickShare(true)
        setIsOpenShareProfileModal(true)
    }

    return (
        <>
            <StyledHostProfileWrapper>
                {(isLoading || isLoadingLike) && <Spinner />}

                <StyledAvatarWrapper>
                    <StyledAvatar src={hostData?.avatar?.url}></StyledAvatar>
                    <StyledGoBackButtonIcon onClick={onGoBackHandler} />
                    <StyledShareButton onClick={onShareClick} />
                </StyledAvatarWrapper>
                <StyledContainer>
                    <StyledStickContainer>
                        <StyledConnectContainer>
                            <StyledFullName>{hostData?.full_name}</StyledFullName>
                            {hostData && isGuest && (
                                <ConnectNowButton
                                    hostId={hostData.id}
                                    mixpanelData={mixpanelData}
                                    className={'connectNowButton'}
                                    handleSetEndTime={handleSetEndTime}
                                />
                            )}
                        </StyledConnectContainer>
                    </StyledStickContainer>

                    <StyledHeartSection onClick={isGuest ? onClickLikeButton : undefined}>
                        <StyledHeartButton>
                            {isLiked || !isGuest ? (
                                <HeartIconFilled width={sizeOfHeart} height={sizeOfHeart} />
                            ) : (
                                <HeartIcon width={sizeOfHeart} height={sizeOfHeart} />
                            )}
                        </StyledHeartButton>

                        {likesCounter >= 100 && (
                            <span>{likesCounter > 1000 ? (likesCounter / 1000).toFixed(3) : likesCounter}</span>
                        )}
                    </StyledHeartSection>
                    <StyledInformSection>
                        {hostsWish?.name || hostData?.description ? (
                            <StyledInformSectionRow>
                                {hostsWish?.name ? (
                                    <StyledInformSectionRowTitle>
                                        {t('rookie_profile_page:host_is', {PROFESSION: hostsWish.name})}
                                    </StyledInformSectionRowTitle>
                                ) : null}
                                {hostData?.description ? (
                                    <StyledInformSectionRowContent>
                                        {hostData.description}
                                    </StyledInformSectionRowContent>
                                ) : null}
                            </StyledInformSectionRow>
                        ) : null}

                        {!!chatTopics.length && (
                            <StyledInformSectionRow>
                                <StyledInformSectionRowTitle>
                                    {t('rookie_profile_page:lets_chat_about')}
                                </StyledInformSectionRowTitle>
                                <StyledInformSectionRowContent>
                                    {chatTopics.map(chatTopic => (
                                        <StyledFavoriteItem key={chatTopic.id} color={chatTopic.color}>
                                            {topics[chatTopic.key_name]}
                                        </StyledFavoriteItem>
                                    ))}
                                </StyledInformSectionRowContent>
                            </StyledInformSectionRow>
                        )}
                        {!!favoriteActivities.length && (
                            <StyledInformSectionRow>
                                <StyledInformSectionRowTitle>
                                    {t('rookie_profile_page:invite_me_to')}
                                </StyledInformSectionRowTitle>
                                <StyledInformSectionRowContent>
                                    {favoriteActivities.map(favoriteActivity => (
                                        <StyledFavoriteItem key={favoriteActivity.id} color={favoriteActivity.color}>
                                            {topics[favoriteActivity.key_name]}
                                        </StyledFavoriteItem>
                                    ))}
                                </StyledInformSectionRowContent>
                            </StyledInformSectionRow>
                        )}
                    </StyledInformSection>

                    {hostData?.id && (
                        <>
                            {isGuest && (
                                <ContainerRow>
                                    <MemberShipPlans hostId={hostData.id} />
                                </ContainerRow>
                            )}

                            <ContainerRow>
                                <HostProfileGoalSection hostId={hostData.id} hasPastGoals={hasPastGoals} />
                            </ContainerRow>
                            <HostProfileMuralSection host={hostData} />
                            {isGuest && (
                                <ContainerRow>
                                    <StyledActionBtnWrapper>
                                        <BlockHostButton hostName={hostData.full_name} hostId={hostData.id} />
                                        {!!connectedHost &&
                                            connectedHost?.subscription_status === SubscriptionStatusesE.ACTIVE && (
                                                <StyledActionBtn
                                                    onClick={() =>
                                                        openCancelRecurringGiftModal({hostID: String(hostData.id)})
                                                    }
                                                >
                                                    {t('common:cancel_gift')}
                                                </StyledActionBtn>
                                            )}
                                    </StyledActionBtnWrapper>
                                </ContainerRow>
                            )}
                        </>
                    )}
                </StyledContainer>
            </StyledHostProfileWrapper>
            {isOpenShareProfileModal && (
                <ShareProfileModal
                    hostGender={hostGender}
                    username={hostData?.username}
                    onClose={() => setIsOpenShareProfileModal(false)}
                />
            )}
        </>
    )
}

HostProfile.displayName = 'HostProfile'
