import {ClockIcon} from '@/assets/icons/icons'
import styled, {css} from 'styled-components'

export const HostProfileGoalSectionContainer = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: ${spacing * 3}px 0 ${spacing * 3}px;
        gap: ${spacing * 2}px;
        & video {
            width: 100%;
            height: 193px;
            object-fit: cover;
        }
        ${mediaQueries.m} {
            & video {
                height: 330px;
            }
        }
    `}
`

export const HostProfileGoalSectionTitle = styled.h1`
    ${({theme: {spacing, typography}}) => css`
        ${typography.textXl}
        position: relative;

        width: 100%;
        padding: 0 0 ${spacing / 2}px;
        color: #00063d;
        font-weight: 700;
        line-height: 140%;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: rgba(127, 130, 157, 0.2);
        }
    `}
`
export const StyledGoalPhoto = styled.img`
    ${({theme: {mediaQueries}}) => css`
        height: 193px;
        object-fit: cover;
        width: 100%;
        ${mediaQueries.m} {
            height: 330px;
        }
    `}
`
export const StyledGoalContainer = styled.div`
    ${({theme: {palette}}) => css`
        border-radius: 4px;
        background: ${palette.neutral.white};
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        cursor: pointer;
    `}
`
export const StyledGoalDescriptionBox = styled.div`
    ${({theme: {spacing, typography, mediaQueries}}) => css`
        display: flex;
        flex-direction: row;
        padding: ${spacing}px ${spacing * 3}px ${spacing}px ${spacing * 2}px;
        ${mediaQueries.m} {
            ${typography.textXl}
        }
    `}
`
export const StyledGoalDescriptionColumn = styled.div<{fullWidth?: boolean}>`
    ${({fullWidth = false, theme: {spacing, mediaQueries, typography}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing / 2}px;
        justify-content: center;
        ${fullWidth &&
        css`
            flex: 1;
        `}
        & text {
            ${typography.textXs}
        }
        ${mediaQueries.m} {
            & text {
                ${typography.textLg}
            }
        }
    `}
`
export const StyledGoalDescriptionRow = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        color: #313663;
        gap: ${spacing / 2}px;
        line-height: 140%;
        font-weight: 400;
        align-items: center;
    `}
`
export const StyledBalanceContainer = styled(StyledGoalDescriptionRow)`
    ${({theme: {spacing}}) => css`
        border-radius: 100px;
        border: 2px solid #d7d7d7;
        width: min-content;
        padding: 0 ${spacing * 1.5}px;
        & > div:first-child {
            border-right: 2px solid #d7d7d7;
            padding-right: ${spacing}px;
        }
    `}
`
export const StyledClockIcon = styled(ClockIcon)`
    ${() => css`
        & path {
            fill: #6c6e80;
        }
    `}
`
export const StyledRestTime = styled.div`
    color: #6c6e80;
`
export const StyledCollectedMoney = styled.div`
    color: #05ce78;
`
export const StyledCurrentBalance = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing / 2}px;
        align-items: center;
        font-weight: 600;
        color: #6c6e80;
    `}
`
export const StyledDesiredBalance = styled(StyledCurrentBalance)`
    color: #8649d1;
`
export const StyledPastGoalButton = styled.div`
    ${({theme: {palette, typography, mediaQueries}}) => css`
        color: ${palette.primary[900]};
        text-align: center;
        font-weight: 700;
        line-height: 140%; /* 28px */
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
        cursor: pointer;
        ${mediaQueries.m} {
            ${typography.textXl}
        }
    `}
`
