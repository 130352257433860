import axios from '@/clientAPI.ts'
import axiosRefactor from '@/refactorClientApi'
import {GuestProfileSchema, ProfilePhotoResponseSchema, ProfileRandomAvatarsSchema} from './profile.schema'
import {EditUserPayload, FirstMessagePayload, MuralTypeList, ProfilePhotoResponseType} from './types'
import {AxiosRequestConfig} from 'axios'
import {User} from '@/features/authentication/types'
import {MURAL_TYPES} from './completeProfile.schema'

export const PROFILE_API = {
    sendProfilePhoto: async (body: FormData, options: AxiosRequestConfig): Promise<ProfilePhotoResponseType> => {
        const url = '/photo'
        const {data} = await axios.post(url, body, {
            ...options,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return ProfilePhotoResponseSchema.parse(data)
    },
    getGuestProfile: async (userId: number | string, representedUserId?: number | string) => {
        const params = representedUserId
            ? {
                  represented_rookie_id: representedUserId
              }
            : undefined
        const url = `/leaders/${userId}`
        const {data} = await axios.get(url, {params})
        return GuestProfileSchema.parse(data)
    },
    unblockUser: async (userId: number) => {
        const url = `/users/${userId}/block`
        return await axios.delete(url)
    },
    blockUser: async (userId: number) => {
        const url = `/users/${userId}/block`
        return await axios.post(url)
    },
    updateProfile: async (data: EditUserPayload): Promise<User> => {
        const url = '/profile/update'
        return await axios.post(url, data)
    },
    fetchRandomAvatars: async () => {
        const url = '/users/avatars'
        const {data} = await axios.get(url)
        return ProfileRandomAvatarsSchema.parse(data)
    },
    deleteUser: async (userId: number) => {
        const url = '/profile/delete'
        return await axios.post(url, {id: {userId}})
    },
    sendFirstMessage: async ({hostId, message, subscription_package_id, micromorgi_amount}: FirstMessagePayload) => {
        const url = `/rookies/${hostId}/channels`
        return await axios.post(url, {
            message: message,
            subscription_package_id: subscription_package_id,
            micromorgi_amount: micromorgi_amount
        })
    },
    unsubscribeUser: async (token: string) => {
        const url = `/profile/unsubscribe/${token}`
        return await axios.get(url)
    },
    updateCreditCard: async () => {
        const {data} = await axios.post(`/profile/credit-card`)
        return data
    },
    getSuggestedLeader: async () => {
        const {data} = await axiosRefactor.get('/rookies/featured-leaders/suggested')
        return data
    },
    postAvatar: async (body: {path_location: string}) => {
        const {data} = await axios.post('/profile/avatar', body)
        return data
    },
    getMuralOfHost: async (body: {
        hostID?: string | number
        page?: string
        limit?: number
        mural_type?: MURAL_TYPES
    }): Promise<MuralTypeList> => {
        const {hostID, ...params} = body
        const {data} = await axios.get(`/rookies/${hostID ? hostID + '/' : ''}mural`, {params})
        return data
    }
}
