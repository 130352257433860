import {useInfiniteQuery} from '@tanstack/react-query'
import {PROFILE_API} from '../services/profile.http'
import {QUERY_KEYS} from './keys'
import {MURAL_TYPES} from '../services/completeProfile.schema'

export const useGetMuralOfHost = ({
    hostID,
    enabled = true,
    limit = 10,
    mural_type
}: {
    hostID?: number | string
    enabled?: boolean
    limit?: number
    mural_type?: MURAL_TYPES
}) => {
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.mural_of_host, hostID, mural_type],
        queryFn: ({pageParam: page}) => PROFILE_API.getMuralOfHost({hostID, page, limit, mural_type}),
        enabled,
        initialPageParam: '0',
        getNextPageParam: ({current_page, last_page}) =>
            current_page !== last_page ? `${current_page + 1}` : undefined
    })
    return {...query, isEmptyList: !query.data?.pages.at(-1)?.data.length}
}
