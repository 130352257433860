import {z} from 'zod'
import {GUEST_EMAIL_MODEL} from '@/features/authentication/services/signupGuest.schema'
import {OptionTypeSchema} from '@/components/commons/select/types/types'
import {MediaTypeE} from '@/types'
import {PageSchema} from '@/features/common/services/common.schema'

export const CompleteProfileValidationSchema = z.object({
    [GUEST_EMAIL_MODEL.GenderID]: z.array(OptionTypeSchema).nonempty({message: 'validation:required_gender_id'}),
    [GUEST_EMAIL_MODEL.ChatTopics]: z.array(OptionTypeSchema).nonempty({message: 'validation:chat_topics_required'}),
    [GUEST_EMAIL_MODEL.Activities]: z.array(OptionTypeSchema).nonempty({message: 'validation:activities_required'}),
    [GUEST_EMAIL_MODEL.SpokenLanguages]: z
        .array(OptionTypeSchema)
        .nonempty({message: 'validation:spoken_languages_required'}),
    [GUEST_EMAIL_MODEL.WishId]: z.array(OptionTypeSchema).nonempty({message: 'validation:required_wish'}),
    [GUEST_EMAIL_MODEL.Description]: z.string().max(120, {message: 'validation:max_description_length'}).optional()
})

export const CompleteProfilePayloadSchema = z.object({
    [GUEST_EMAIL_MODEL.ChatTopics]: z.array(z.number()),
    [GUEST_EMAIL_MODEL.Activities]: z.array(z.number()),
    [GUEST_EMAIL_MODEL.SpokenLanguages]: z.array(z.number()),
    [GUEST_EMAIL_MODEL.WishId]: z.number(),
    [GUEST_EMAIL_MODEL.GenderID]: z.number()
})

export enum MURAL_TYPES {
    SURPRISES = 'surprises',
    MEDIA_PACKAGES = 'media_packages',
    VIDEOS = 'videos',
    PHOTOS = 'photos'
}

export const MediaPackageContentSchema = z.object({
    id: z.number(),
    user_id: z.number(),
    media_package_id: z.number(),
    type: z.enum(['image', 'video']),
    path_location: z.string(),
    blurred_url: z.string(),
    blurred_path_location: z.string(),
    created_at: z.string(),
    updated_at: z.string(),
    deleted_at: z.string().nullable(),
    url: z.string().nullable()
})

export const MediaPackageSchema = z.object({
    id: z.number(),
    user_id: z.number(),
    name: z.string().optional(),
    amount: z.number().optional(),
    created_at: z.string(),
    updated_at: z.string(),
    deleted_at: z.string().nullable(),
    media_packages_contents: z.array(MediaPackageContentSchema).optional(),
    media_packages_contents_counts: z.record(z.enum(['image', 'video']), z.number()).optional(),
    is_media_package_bought: z.boolean().optional(),
    mural_type: z.nativeEnum(MURAL_TYPES),
    blurred_url: z.string().optional(),
    blurred_path_location: z.string().optional(),
    is_processed: z.number().optional(),
    under_validation: z.boolean().optional(),
    url: z.string().nullable().optional(),
    path_location: z.string().nullable().optional(),
    type: z.nativeEnum(MediaTypeE).optional()
})
export const MediaPackagesArraySchema = PageSchema(MediaPackageSchema)
