import {MicromorgiIcon} from '@/assets/icons/icons'
import {CircleProgress} from '@/components/ui/circle-progress/CircleProgress'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {progressGradient} from '@/features/goal/constant/goalCard'
import {useGoalsListOfUser} from '@/features/goal/queries/useGoalsListOfUser'
import {GoalStatusesE} from '@/features/goal/types'
import useContainerDimensions from '@/hooks/useContainerDimensions'
import {breakpoints} from '@/theme/mediaQueries'
import {MediaTypeE} from '@/types'
import {ROUTE_GOAL, ROUTE_PAST_GOALS} from '@/utilities/constants/routeNames'
import {
    calculatePercentage,
    checkMediaExtension,
    formatTimeDifference,
    generateGoalSlug,
    TimeMode
} from '@/utilities/helpers'
import {FC, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'
import {
    HostProfileGoalSectionContainer,
    HostProfileGoalSectionTitle,
    StyledBalanceContainer,
    StyledClockIcon,
    StyledCollectedMoney,
    StyledCurrentBalance,
    StyledDesiredBalance,
    StyledGoalContainer,
    StyledGoalDescriptionBox,
    StyledGoalDescriptionColumn,
    StyledGoalDescriptionRow,
    StyledGoalPhoto,
    StyledPastGoalButton,
    StyledRestTime
} from './style'

type HostProfileGoalSectionProps = {
    hostId: number
    hasPastGoals?: boolean
}
const MOBILE_SIZE = breakpoints.width.m
const HostProfileGoalSection: FC<HostProfileGoalSectionProps> = ({hostId, hasPastGoals = false}) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {data: goals, isLoading} = useGoalsListOfUser(hostId)
    const [goal] = goals || []
    const {media} = goal || {media: []}
    const goalData = media?.[0]
    const {width} = useContainerDimensions()
    const [isMobile, setIsMobile] = useState<boolean>(width <= MOBILE_SIZE)
    const type = goalData?.url && checkMediaExtension(goalData.url)?.type
    const isPhoto = type === MediaTypeE.PHOTO

    useEffect(() => {
        setIsMobile(width <= MOBILE_SIZE)
    }, [width])

    const percentage = calculatePercentage(
        parseInt(String(goal?.donations_count)),
        parseInt(goal?.target_amount ?? '0')
    )
    const convertToCorrectViewBalance = (balance: number | undefined) => {
        if (!balance) return 0
        if (balance < 1000) return balance
        return (balance / 1000).toFixed(3)
    }
    const goalSlug = generateGoalSlug(`${goal?.slug}`, `${goal?.id}`)
    const handleCardClick = () => {
        navigate(generatePath(ROUTE_GOAL, {slug: goalSlug}))
    }
    const strokeColor =
        {
            [GoalStatusesE.SUCCESSFUL]: progressGradient.success,
            [GoalStatusesE.AWAITING_PROOF]: progressGradient.success,
            [GoalStatusesE.PROOF_PENDING_APPROVAL]: progressGradient.success,
            [GoalStatusesE.CANCELED]: progressGradient.fail
        }[
            goal?.status as
                | GoalStatusesE.SUCCESSFUL
                | GoalStatusesE.AWAITING_PROOF
                | GoalStatusesE.PROOF_PENDING_APPROVAL
                | GoalStatusesE.CANCELED
        ] || progressGradient.default

    const onClickPastGoalButtonHandler = () => {
        if (hasPastGoals) navigate(ROUTE_PAST_GOALS.replace(':rookieId', `${hostId}`))
    }

    if (!goal) return null

    return (
        <HostProfileGoalSectionContainer>
            {isLoading ? (
                <Spinner center={false} inline={true} overlay={false} />
            ) : (
                <>
                    <HostProfileGoalSectionTitle>{t('common:goals')}</HostProfileGoalSectionTitle>
                    <StyledGoalContainer onClick={handleCardClick}>
                        {isPhoto ? (
                            <StyledGoalPhoto src={goalData?.url} />
                        ) : (
                            <video preload="metadata">
                                <source src={`${goalData?.url}#t=0.1`} />
                            </video>
                        )}
                        <StyledGoalDescriptionBox>
                            <StyledGoalDescriptionColumn fullWidth>
                                <StyledGoalDescriptionRow>{goal?.name}</StyledGoalDescriptionRow>
                                <StyledGoalDescriptionRow>
                                    <StyledClockIcon width={20} height={20} />
                                    {goal && (
                                        <StyledRestTime>
                                            {formatTimeDifference(goal.end_date, t, TimeMode.UNTIL)}
                                        </StyledRestTime>
                                    )}

                                    {` | `}
                                    <StyledCollectedMoney>{`${percentage.toFixed(0)}% ${t('common:funded')}`}</StyledCollectedMoney>
                                </StyledGoalDescriptionRow>
                                {goal && (
                                    <StyledBalanceContainer>
                                        <StyledCurrentBalance>
                                            <MicromorgiIcon width={isMobile ? 16 : 22} height={isMobile ? 16 : 22} />
                                            {convertToCorrectViewBalance(Number(goal.donations_count))}{' '}
                                        </StyledCurrentBalance>

                                        <StyledDesiredBalance>
                                            <MicromorgiIcon width={isMobile ? 16 : 22} height={isMobile ? 16 : 22} />
                                            {convertToCorrectViewBalance(Number(goal.target_amount))}
                                        </StyledDesiredBalance>
                                    </StyledBalanceContainer>
                                )}
                            </StyledGoalDescriptionColumn>
                            <StyledGoalDescriptionColumn>
                                <CircleProgress
                                    size={isMobile ? 48 : 85}
                                    percentage={percentage}
                                    progressColor={strokeColor}
                                />
                            </StyledGoalDescriptionColumn>
                        </StyledGoalDescriptionBox>
                    </StyledGoalContainer>
                    {hasPastGoals && (
                        <StyledPastGoalButton onClick={onClickPastGoalButtonHandler}>
                            {t('pages:my_goals:past_goals')}
                        </StyledPastGoalButton>
                    )}
                </>
            )}
        </HostProfileGoalSectionContainer>
    )
}
export default HostProfileGoalSection
