import styled, {css} from 'styled-components'

export const StyledUnpaidContentWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 1.75}px;
    `}
`
export const StyledPackageName = styled.h3`
    ${({theme: {palette, typography, mediaQueries}}) => css`
        color: ${palette.neutral.white};
        text-align: center;
        font-weight: 800;
        text-transform: capitalize;
        ${typography.textXl}
        ${mediaQueries.m} {
            ${typography.text2Xl}
        }
    `}
`
export const StyledCounterItemsContainer = styled.div`
    ${({theme: {spacing, palette, typography, mediaQueries}}) => css`
        display: flex;
        gap: ${spacing}px;
        color: ${palette.neutral.white};
        font-weight: 600;
        line-height: 140%; /* 28px */
        align-items: center;
        ${typography.textXl}
        ${mediaQueries.m} {
            ${typography.text4Xl}
        }
    `}
`
export const StyledCounterItem = styled.div`
    ${({theme: {spacing, palette, mediaQueries, typography, flexUtils}}) => css`
        ${flexUtils.centered}
        ${typography.textXs}
        gap: ${spacing}px;
        border-radius: 12px;
        background: #7f7f7f;
        padding: 0 ${spacing}px;
        color: ${palette.neutral.white};
        font-weight: 700;
        height: 15px;
        ${mediaQueries.m} {
            ${typography.textLg}
            gap: ${spacing * 1.75}px;
            height: 23px;
        }
    `}
`
