import {z} from 'zod'
import {UserRoleE} from '@/types'
import {OptionTypeSchema} from '@/components/commons/select/types/types'

export const enum GUEST_EMAIL_MODEL {
    Email = 'email',
    Password = 'password',
    GenderID = 'gender_id',
    Activities = 'favorite_activities_ids',
    ChatTopics = 'chat_topics_ids',
    WishId = 'wish_id',
    SpokenLanguages = 'spoken_languages_ids',
    Type = 'type',
    Username = 'username',
    PathLocation = 'path_location',
    Description = 'description',
    RemoveAvatar = 'remove_avatar',
    Token = 'auth_token'
}

export const SignupGuestValidationSchema = z.object({
    [GUEST_EMAIL_MODEL.Email]: z.string().min(1, {message: 'validation:required_email'}).email({
        message: 'validation:invalid_email'
    }),
    [GUEST_EMAIL_MODEL.ChatTopics]: z.array(OptionTypeSchema).optional(),
    [GUEST_EMAIL_MODEL.Activities]: z.array(OptionTypeSchema).optional(),
    [GUEST_EMAIL_MODEL.SpokenLanguages]: z
        .array(OptionTypeSchema)
        .nonempty({message: 'validation:spoken_languages_required'}),
    [GUEST_EMAIL_MODEL.GenderID]: z.array(OptionTypeSchema).optional(),
    [GUEST_EMAIL_MODEL.WishId]: z.array(OptionTypeSchema).optional(),
    [GUEST_EMAIL_MODEL.Token]: z.string().optional()
});

export const SignupGuestPayloadSchema = z.object({
    [GUEST_EMAIL_MODEL.Type]: z.nativeEnum(UserRoleE),
    [GUEST_EMAIL_MODEL.Email]: z.string(),
    [GUEST_EMAIL_MODEL.GenderID]: z.number().optional(),
    [GUEST_EMAIL_MODEL.ChatTopics]: z.array(z.number()).optional(),
    [GUEST_EMAIL_MODEL.Activities]: z.array(z.number()).optional(),
    [GUEST_EMAIL_MODEL.SpokenLanguages]: z.array(z.number().optional()).optional(),
    [GUEST_EMAIL_MODEL.WishId]: z.number().optional(),
    [GUEST_EMAIL_MODEL.Token]: z.string().optional(),
    language: z.string(),
    first_rookie: z.string().optional(),
    public_group: z.number().optional(),
    referral_code: z.string().optional(),
    path_id: z.string().optional(),
    persona: z.string().optional(),
    goal_id: z.string().optional(),
    tc_id: z.string().optional()
})
