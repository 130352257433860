import {InfoIcon} from '@/assets/icons/icons'
import {Button} from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'

export const StyledHostProfileTaxiSectionContainer = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 3}px;
        padding: 0px 0px ${spacing * 2}px;
        max-width: 100%;
        ${mediaQueries.m} {
            padding: 0px 0px ${spacing * 3}px;
        }
    `}
`
export const StyledHostProfileTaxiSectionHeader = styled.div`
    ${() => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
    `}
`
export const StyledHostProfileTaxiSectionHeaderTitle = styled.h3`
    ${({theme: {mediaQueries, palette, typography}}) => css`
        ${typography.textXl}
        color: ${palette.primary[900]};
        text-align: center;
        font-weight: 700;
        line-height: 140%; /* 28px */
        ${mediaQueries.m} {
            ${typography.text2Xl}
        }
    `}
`
export const StyledHostProfileTaxiSectionList = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        display: flex;
        gap: ${spacing}px;
        max-width: 343px;
        overflow-x: auto;
        padding-bottom: ${spacing}px;

        &::-webkit-scrollbar {
            height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: #ccc;
            border-radius: 3px;
        }

        ${mediaQueries.m} {
            max-width: 600px;
        }
    `}
`

export const StyledTaxiUser = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing}px;
        border-radius: 8px;
        border: 1px solid rgba(134, 73, 209, 0.2);
        background: #fff;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
        justify-content: space-between;
        padding: ${spacing}px;

        height: 135px;
        width: 110px;
        flex-shrink: 0;

        ${mediaQueries.m} {
            padding: ${spacing * 2}px;

            height: 159px;
            width: 158px;
        }
    `}
`
export const StyledInfoIcon = styled(InfoIcon)`
    ${() => css`
        cursor: pointer;
    `}
`
export const StyledTaxiUserFullName = styled.h3`
    ${({theme: {mediaQueries, typography}}) => css`
        color: #212651;
        text-align: center;
        font-weight: 600;
        line-height: 130%; /* 26px */
        letter-spacing: -0.3px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        ${mediaQueries.m} {
            ${typography.textXl}
        }
    `}
`
export const StyledTaxiUserCTA = styled(Button)`
    ${({theme: {mediaQueries, typography}}) => css`
        ${typography.textXs}
        padding: 0;
        color: #8649d1;
        text-align: center;
        font-weight: 500;
        text-transform: capitalize;
        border-color: #8649d1;
        ${mediaQueries.m} {
            ${typography.textLg}
        }
    `}
`
