import styled, {css} from 'styled-components'

export const StyledMediaWrapper = styled.div`
    ${({theme: {flexUtils}}) => css`
        ${flexUtils.centered}
        height: 100%;
        width: 100%;
        position: relative;
        object-fit: cover;
        overflow: hidden;
        & > video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    `}
`
export const StyledStarredMessageWrapper = styled.div`
    ${({theme: {spacing, typography, palette, mediaQueries}}) => css`
        ${typography.text4Xl};
        width: 100%;
        border-radius: 5px 0px 5px 5px;
        background: rgba(255, 255, 255, 0.1);
        font-weight: 700;
        word-break: break-all;
        & > span {
            padding: ${spacing}px;
            max-height: 4.5em;
            color: ${palette.neutral.black};
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            box-orient: vertical;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -moz-line-clamp: 2;
            white-space: pre-wrap;
            letter-spacing: -0.472px;
            line-height: 1.5;
        }
        ${mediaQueries.m} {
            ${typography.displayLg};
            & > span {
                line-clamp: 3;
                -webkit-line-clamp: 3;
                -moz-line-clamp: 3;
            }
        }
    `}
`

export const StyledShadow = styled.div<{isPaid?: boolean}>`
    ${({isPaid = true}) => css`
        position: relative;
        width: 100%;
        height: 100%;
        ${!isPaid &&
        css`
            &:after {
                content: '';
                position: absolute;
                inset: 0;
                background: rgba(199, 169, 214, 0.77);
            }
        `}
        .blur {
            filter: blur(5px);
        }
        .splide,
        .splide__track,
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        .splide__pagination {
            bottom: -30px;
        }

        .splide__pagination__page {
            width: 10px;
            height: 10px;
            background-color: #d9d9d9;
            transition: all 0.3s ease;
        }

        .splide__pagination__page.is-active {
            background-color: #00063d;
            transform: scale(1.2);
        }
    `}
`
export const StyledBox = styled.div`
    ${({theme: {flexUtils}}) => css`
        width: 100%;
        height: 100%;
        ${flexUtils.centered};
    `}
`
export const mediaStyles = () => css`
    box-shadow: none;
    overflow: hidden;
    border-radius: 46px;
`

export const StyledPlayButtonWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        position: absolute;
        offset: 0;
        z-index: 1;
        & svg {
            border-radius: 8px;
            padding: ${spacing * 1.5}px;
            border: 2px solid ${palette.neutral.white};
        }
    `}
`
export const StyledUnpaidContent = styled.div`
    ${({theme: {flexUtils}}) => css`
        ${flexUtils.centered}
        position: absolute;
        offset: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    `}
`
