import {HostPartialType} from '@/features/authentication/types'
import {CHAT_API} from '@/features/chat/services/chat.http'
import {ChannelID} from '@/features/chat/types'
import {useHandleError} from '@/hooks/useHandleError'
import {useRootStore} from '@/store'
import {selectChatStore} from '@/store/selectors'
import Pubnub from 'pubnub'
import {useCallback, useEffect, useState} from 'react'
import basePubnubConfig from '../../chat/pubnubConfig'
import {ChannelsType} from '../types'

const useUnreadMessagesCountReps = ({
    host,
    enabled = true
}: {
    host?: HostPartialType & {hostChannels?: ChannelsType}
    enabled?: boolean
}) => {
    const [error, setError] = useState<Error | null>(null)
    const [count, setCount] = useState<number | null>(null)
    const [pubnubInstance, setPubnubInstance] = useState<Pubnub | null | undefined>(null)
    const [isLoading, setIsLoading] = useState(false)
    const {setUnreadCounter} = useRootStore(selectChatStore)
    useHandleError({isError: !!error, error, isBlankPage: true})
    const getMessageCounts = async ({
        pubnubInstance,
        channels = {}
    }: {
        pubnubInstance: Pubnub
        channels: ChannelsType
    }) => {
        try {
            setIsLoading(true)
            if (Object.keys(channels).length && pubnubInstance) {
                const channelsLastReadTimetokens: string[] = []

                const channelsNames = Object.values(channels).reduce((acc, {display_name, last_reads_timetokens}) => {
                    if (last_reads_timetokens?.length) {
                        acc.push(display_name)
                        const lastReadTimetoken = last_reads_timetokens[0].timetoken
                        channelsLastReadTimetokens.push(lastReadTimetoken)
                    } else {
                        acc.push(display_name)
                        channelsLastReadTimetokens.push('0')
                    }
                    return acc
                }, [] as string[])
                const counters = await pubnubInstance.messageCounts({
                    channels: channelsNames,
                    channelTimetokens: channelsLastReadTimetokens
                })
                Object.keys(counters.channels).forEach(key => {
                    const channelName = key as ChannelID
                    setUnreadCounter(channelName, counters?.channels?.[key])
                })
                return Object.values(counters.channels).reduce((acc, item) => (item > 0 ? acc + item : acc), 0)
            }
        } catch (error) {
            console.log('Error in getting message counts', error)
        } finally {
            setIsLoading(false)
        }
    }

    const getCount = async () => {
        const count =
            pubnubInstance &&
            host &&
            host.hostChannels &&
            (await getMessageCounts({pubnubInstance, channels: host.hostChannels}))

        setCount(Number(count))
    }

    const leaveChat = useCallback(() => {
        if (pubnubInstance) {
            console.log('leaveChat')
            pubnubInstance.unsubscribeAll()
        }
    }, [pubnubInstance])

    const clear = () => {
        setPubnubInstance(null)
        setCount(null)
    }

    useEffect(() => {
        if (pubnubInstance && enabled) getCount()

        return () => {
            if (pubnubInstance && enabled) {
                clear()
                leaveChat()
            }
        }
    }, [host, pubnubInstance, enabled])

    useEffect(() => {
        const initPubnub = async ({hostId}: {hostId: number}) => {
            try {
                const params = {
                    represented_rookie_id: hostId
                }

                const {
                    data: {uuid, authKey}
                } = await CHAT_API.initChat(params)
                return new Pubnub({
                    ...basePubnubConfig,
                    userId: `${uuid}`,
                    authKey
                })
            } catch (err) {
                if (err instanceof Error) {
                    setError(err)
                }
            }
        }
        !!host?.id && enabled && initPubnub({hostId: host.id}).then(setPubnubInstance)
    }, [enabled])

    return {count, isLoading}
}

export default useUnreadMessagesCountReps
