import {HostSchema} from '@/features/authentication/services/auth.schema'
import {PageSchema} from '@/features/common/services/common.schema'
import {z} from 'zod'
import {MyMomentsTypesE} from '../types'
import {GoalProofSchema} from '@/features/goal/services/goal.schema'
import {MySurpriseSchema} from '@/features/my-surprises/services/mySurprises.schema'
import {MediaPackageSchema} from '@/features/profile/services/completeProfile.schema'

export const MyMomentTypes = z.nativeEnum(MyMomentsTypesE)

export const ChatAttachmentSchema = z.object({
    id: z.number(),
    sender_id: z.number(),
    receiver_id: z.number(),
    type: z.string(),
    path_location: z.string(),
    blurred_path_location: z.union([z.string(), z.null()]),
    title: z.union([z.string(), z.null()]),
    morgi: z.union([z.number(), z.null()]),
    representative_id: z.union([z.number(), z.null()]),
    representative_first_name: z.union([z.string(), z.null()]),
    url: z.union([z.string().url(), z.null()]),
    blurred_url: z.union([z.string().url(), z.null()]),
    paid_users: z.array(z.number()),
    group_key_name: z.union([z.string(), z.null()])
})

const StarredMessageSchema = z.object({
    id: z.number(),
    type: z.enum(['message']),
    channel_id: z.number(),
    sender_id: z.number(),
    receiver_id: z.number(),
    representative_id: z.union([z.number(), z.null()]),
    is_starred: z.number(), //we received 1 or 0
    text: z.string(),
    language: z.null(),
    sent_at: z.string(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime()
})

export const MyMomentSchema = z.object({
    chat_attachment: z.union([ChatAttachmentSchema, z.null()]),
    created_at: z.string().datetime(),
    goal_proof: z.union([GoalProofSchema, z.null()]),
    id: z.number(),
    leader_id: z.number(),
    rookie_id: z.number(),
    rookie: HostSchema.deepPartial(),
    starred_message: z.union([StarredMessageSchema, z.null()]),
    updated_at: z.string().datetime(),
    type: MyMomentTypes,
    surprise: z.union([MySurpriseSchema, z.null()]),
    media_package: MediaPackageSchema.omit({mural_type: true}).optional().nullable()
})

export const MyMomentsFilterCategorySchema = z.object({
    count: z.number(),
    type: MyMomentTypes,
    url: z.string().url().optional(),
    id: z.number().optional()
})
export const MyMomentsResponseSchema = PageSchema(MyMomentSchema)
export const MyMomentsFilteredCategoriesResponseSchema = z.array(MyMomentsFilterCategorySchema)
export const MyMomentsFilteredHostsResponseSchema = PageSchema(HostSchema)
