import {OptionProps as OptionPropsReactSelect, GroupBase} from 'react-select'
import {ReactNode, Ref} from 'react'
import {DefaultNamespace} from 'i18next'
import z from 'zod'
import Select from 'node_modules/react-select/dist/declarations/src/Select'
import {DefaultError, UseMutateFunction} from '@tanstack/react-query'

export type SubSelect = {
    label: string
    value: string
}
export const OptionTypeSchema = z.object({
    id: z.union([z.number(), z.string()]),
    name: z.string(),
    key_name: z.string().optional(),
    is_popular: z.boolean().optional(),
    users_count: z.number().optional(),
    code: z.string().optional(),
    is_translatable: z.boolean().optional(),
    is_subpath: z.boolean().optional(),
    prepend: z.string().optional(),
    disabled: z.boolean().optional(),
    value: z.string(),
    label: z.string(),
    subOption: z.object({label: z.string(), value: z.string()}).optional(),
    extraPayload: z.unknown().optional() //For different data
})

export type OptionType = z.infer<typeof OptionTypeSchema>
export type GroupedOptionType = {
    label?: string
    options: OptionType[]
    value?: string
}

export interface SelectProps {
    className?: string
    icon?: JSX.Element
    closeAfterSelect?: boolean
    subSelectPlaceholder?: string
    name?: string
    canBeSaved?: boolean
    showCheckbox?: boolean
    maxItemsErrorMessage?: string
    subSelect?: SubSelect[]
    options: OptionType[] | GroupedOptionType[]
    label?: string | DefaultNamespace
    placeholder?: string | DefaultNamespace
    helpText?: string | DefaultNamespace
    readOnly?: boolean
    isLoadingSaveItem?: boolean
    defaultValue?: OptionType | OptionType[]
    isSearchable?: boolean
    isClearable?: boolean
    touched?: boolean
    requestAddNewOption?: UseMutateFunction<unknown, DefaultError, string>
    onSaveNewOption?: (optionName: string) => void
    isCreatable?: boolean
    formatCreateLabel?: (value: string) => ReactNode
    addOptionMessage?: string
    maxItems?: number
    isMulti?: boolean
    closeMenuOnSelect?: boolean
    disabled?: boolean
    size?: 'medium' | 'large'
    onChange?: (newValue: OptionType[]) => void
    onBlur?: () => void
    value?: OptionType[]
    showSelectedOptions?: boolean
    ref?: Ref<Select<OptionType, boolean, GroupBase<OptionType>>> | undefined
    errorMessage?: string | DefaultNamespace
    errorMessages?: {
        required?: string | DefaultNamespace
        maxLimit?: string | DefaultNamespace
        subOptionNotSelected?: string | DefaultNamespace
    }
    countable?: boolean
    maxLength?: number
    menuPlacement?: 'top' | 'bottom'
    maxMenuHeight?: number
    customLabelHandler?: (option: OptionType) => string
}

export type OptionProps = {
    subSelectPlaceholder?: string
    optionData: OptionPropsReactSelect<OptionType, boolean, GroupBase<OptionType>>
    isMulti: boolean
    subSelect?: SubSelect[]
    onSelectSubOption?: (option: OptionType, subOption: SubSelect) => void
    selectedOptions: OptionType[]
    showCheckbox: boolean
}

export type LabelSelectProps = {
    selectedOption: OptionType
    removeSelectOption: (id: number) => void
    icon?: JSX.Element
    customLabelHandler?: (option: OptionType) => string
}

export type SelectForOptionProps = {
    options: SubSelect[]
    defaultValue?: SubSelect | null
    onChange: (subOption: SubSelect) => void
    placeholder?: string
}
