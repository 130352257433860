import styled, {css, DefaultTheme} from 'styled-components'

export const StyledWrapper = styled.div`
    ${({theme: {zIndex, scrollbar, palette}}) => css`
        background: ${palette.neutral.white};
        display: block;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        position: fixed;
        z-index: ${zIndex.modal};
        overflow-y: auto;
        padding-bottom: 0;
        ${scrollbar}
    `}
`
export const modalStyles = (theme: DefaultTheme) => css`
    ${theme.mediaQueries.m} {
        height: fit-content;
    }
`
