import styled, {css} from 'styled-components'

export const StyledToolTipActionsContainer = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 3.5}px;
        padding: ${spacing * 2}px;
        margin-top: ${spacing * 1.5}px;
    `}
`
export const StyledToolTipActionContent = styled.h2`
    ${() => css`
        line-height: 140%;
        letter-spacing: -0.14px;
    `}
`
export const StyledToolTipImage = styled.div`
    ${() => css`
        height: 90px;
        & > img {
            min-width: 90px;
            max-height: 100%;
        }
    `}
`
