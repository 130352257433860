import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'
import {useRootStore} from '@/store'
import {selectHostProfileModal} from '@/store/selectors.ts'
import {FC, useEffect} from 'react'
import {HostProfile} from '../host-profile/HostProfile'
import {StyledWrapper} from './style'
import {Header} from '@/components/commons/header/Header'

export const HostProfileModal: FC = () => {
    const {closeModal: onClose, hostUsername} = useRootStore(selectHostProfileModal)
    const mixpanel = useMixPanel()

    useEffect(() => {
        mixpanel?.startTimeEvent(MixpanelEventNameE.ProfileModalClose)
    }, [])

    const handleClose = () => {
        onClose()
    }
    return (
        <StyledWrapper>
            <Header />
            <HostProfile isModal closeModal={handleClose} hostUserName={hostUsername} />
        </StyledWrapper>
    )
}

HostProfileModal.displayName = 'HostProfileModal'
