import {PrivateDataLayer} from '@layouts/private-data-layer/PrivateDataLayer.tsx'
import {PublicLayout} from '@layouts/public-layout/PublicLayout.tsx'
import {GuestRoute} from '@route-guards/guest-route/GuestRoute.tsx'
import {HostRoute} from '@route-guards/host-route/HostRoute.tsx'
import {PrivateRoute} from '@route-guards/private-route/PrivateRoute.tsx'
import {PublicRoute} from '@route-guards/public-route/PublicRoute.tsx'
import {RepresentativeRoute} from '@route-guards/representative-route/RepresentativeRoute.tsx'
import {commonRoutes, goalRoutes, guestRoutes, hostRoutes} from '@utilities/constants'
import {createBrowserRouter} from 'react-router-dom'
import {ErrorBoundary} from './ErrorBoundary'
import {SharedLayout} from './layouts/shared-layout/SharedLayout'
import {UserStatusControlRoute} from '@route-guards/user-status-control-route/UserStatusControlRoute.tsx'
import {PrivateLayout} from '@layouts/private-layout/PrivateLayout.tsx'
import {BlockedUserGuard} from './route-guards/user-status-control-route/bloked-user-guard/BlockedUserGuard'
import InteractiveFeed from '@/pages/guest-pages/interactive-feed/InteractiveFeed.tsx'
import {NotFound} from './pages/not-found/NotFound'
import {AgentRoute} from './route-guards/agent-route/AgentRoute'

const router = createBrowserRouter(
    [
        {
            element: <SharedLayout />,
            errorElement: <ErrorBoundary />,
            children: [
                {...commonRoutes.BLOCKED_USER},
                {...commonRoutes.ACCEPT_JOIN_REQUEST},
                {...commonRoutes.NO_HOSTS},
                {
                    element: <BlockedUserGuard />,

                    children: [
                        {
                            element: <PrivateDataLayer />,
                            errorElement: <ErrorBoundary />,
                            children: [
                                {
                                    element: <PrivateRoute />, //all authenticated user layout route
                                    errorElement: <ErrorBoundary />,
                                    children: [
                                        {
                                            element: <PrivateLayout />,
                                            children: [
                                                {...commonRoutes.ADD_NEW_INFO_PAGE},
                                                {...commonRoutes.COMPLETE_PROFILE},
                                                {...hostRoutes.CONNECT_TELEGRAM_PAGE},
                                                {...hostRoutes.WELCOME_BACK_PAGE},
                                                {...commonRoutes.DELETE_PROFILE},
                                                {...hostRoutes.TOP_FAN_GUESTSBOARD},
                                                {
                                                    element: <UserStatusControlRoute />,
                                                    children: [
                                                        // {...commonRoutes.CHAT},
                                                        // {...commonRoutes.CHANGE_PASSWORD},
                                                        {...commonRoutes.EDIT_PROFILE},
                                                        {...commonRoutes.MESSAGE_CENTER_CHAT},
                                                        {...goalRoutes.GOAL},
                                                        {...hostRoutes.HOST_PROFILE},
                                                        {...hostRoutes.INVITE_FRIENDS},
                                                        {...hostRoutes.TRANSACTION_HISTORY},
                                                        {...hostRoutes.HOST_PAYMENT_METHOD},
                                                        {
                                                            element: <RepresentativeRoute />,
                                                            children: [{...commonRoutes.EDIT_REPRESENTATIVE}]
                                                        },
                                                        {
                                                            element: <AgentRoute />,
                                                            children: [
                                                                {...hostRoutes.MANAGE_REPS},
                                                                {...hostRoutes.TRANSACTION_HISTORY}
                                                            ]
                                                        },
                                                        {
                                                            element: <HostRoute />,
                                                            children: [
                                                                {...hostRoutes.HOST_MY_PROFILE},
                                                                {...hostRoutes.HOST_MANAGE_AGENT},
                                                                {...hostRoutes.HOST_GENERAL_TIPS},
                                                                {...hostRoutes.HOST_MYSELF},
                                                                {...hostRoutes.HOST_SCORE},
                                                                {...goalRoutes.MY_GOALS},
                                                                {...goalRoutes.CREATE_GOAL},
                                                                {...goalRoutes.GOAL_PROOF},
                                                                {...goalRoutes.GOALS_TOUR},
                                                                {...goalRoutes.SUPPORTER_LEADER_PAGE},
                                                                {...goalRoutes.SELECT_GOAL_TYPE},
                                                                {...hostRoutes.ACTIVE_GIFTING_GUESTS},
                                                                {...hostRoutes.GUEST_GIF_HISTORY},
                                                                {...goalRoutes.DETAILED_TRANSACTION},
                                                                {...hostRoutes.WELCOME_HOST},
                                                                {...hostRoutes.APPROACH_FIRST},
                                                                {...hostRoutes.REFER_A_GUEST},
                                                                {...hostRoutes.TELEGRAM_BOT},
                                                                {...hostRoutes.HOST_PHOTO_UPLOAD},
                                                                {...hostRoutes.HOST_VIDEO_UPLOAD},
                                                                {...hostRoutes.HOST_DAILY_INSPIRATION},
                                                                {...hostRoutes.UPLOAD_YOUR_SURPRISE},
                                                                {...hostRoutes.MY_MORGIS_TAXI},
                                                                {...hostRoutes.UPLOAD_MEDIA}
                                                            ]
                                                        },
                                                        {
                                                            element: <GuestRoute />,
                                                            children: [
                                                                {
                                                                    element: <InteractiveFeed />,
                                                                    children: [
                                                                        {...guestRoutes.HOME},
                                                                        {...goalRoutes.GOALS_MAIN_PAGE}
                                                                    ]
                                                                },
                                                                {...guestRoutes.SAVED_HOSTS},
                                                                {...guestRoutes.BIRTHDAYS},
                                                                {...guestRoutes.REFER_A_HOST},
                                                                {...guestRoutes.MY_MICROMORGI_BALANCE},
                                                                {...guestRoutes.ACTIVE_GIFTS},
                                                                {...guestRoutes.PATH_FILTER},
                                                                {...guestRoutes.CHECK_PAYMENT},
                                                                {...guestRoutes.UPDATE_CREDIT_CARD},
                                                                {...guestRoutes.UPDATE_RECURRING_TRANSACTION},
                                                                {...guestRoutes.GUEST_QUOTES},
                                                                {...guestRoutes.SECOND_CHANCE},
                                                                {...guestRoutes.COUPON_HISTORY},
                                                                {...guestRoutes.ON_BOARD_TOUR},
                                                                {...guestRoutes.SIGNIN_AFTER_EMAIL},
                                                                {...guestRoutes.MY_MOMENTS_FEED},
                                                                {...guestRoutes.WHOS_TOP_FAN_YOU_ARE},
                                                                {...guestRoutes.BILLING_INFO},
                                                                {...goalRoutes.SAVED_GOALS},
                                                                {...goalRoutes.SUPPORTED_GOALS},
                                                                {...goalRoutes.PAST_GOALS},
                                                                {...goalRoutes.WHAT_ARE_GOALS},
                                                                {...goalRoutes.DETAILED_TRANSACTION}
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    element: <PublicRoute />, // anonymous user layout route
                                    errorElement: <ErrorBoundary />,
                                    children: [
                                        {
                                            element: <PublicLayout />,
                                            children: [
                                                {...commonRoutes.LOGIN_GUEST},
                                                {...commonRoutes.LOGIN_HOST},
                                                {...commonRoutes.SIGNUP_HOST},
                                                {...commonRoutes.SIGNUP_HOST_SPECIAL},
                                                {...commonRoutes.SIGNUP_GUEST},
                                                {...commonRoutes.SIGNUP_GUEST_SOCIAL},
                                                {...commonRoutes.SIGNUP_HOST_SOCIAL},
                                                {...commonRoutes.SIGNUP_REPRESENTATIVE},
                                                {...commonRoutes.SIGNUP_AGENT},
                                                {...commonRoutes.SIGNUP_SUCCESS},
                                                {...commonRoutes.SIGNUP_ACTIVATION},
                                                {...commonRoutes.LOGIN_EMAIL_VERIFICATION},
                                                {...commonRoutes.SIGNUP_EMAIL_VERIFICATION},
                                                {...commonRoutes.LINK_ACCOUNTS},
                                                {...commonRoutes.UNSUBSCRIBE},
                                                {...commonRoutes.PASSWORD_RECOVERY}
                                            ]
                                        }
                                    ]
                                },
                                //pages available for unauthorized users or for all user roles
                                {...commonRoutes.TERMS_AND_CONDITIONS},
                                {...commonRoutes.COMMUNITY_GUIDELINES},
                                {...commonRoutes.FAQ_HOST},
                                {...commonRoutes.FAQ_GUEST},
                                {...commonRoutes.WHAT_IS_MORGI},
                                {...commonRoutes.A_WORD_FROM_PRESIDENT},
                                {...commonRoutes.OUR_MISSION},
                                {...commonRoutes.WHO_WE_ARE},
                                {...commonRoutes.COOKIE_POLICY},
                                {...commonRoutes.PRIVACY_POLICY},
                                {...commonRoutes.TOS_GUEST},
                                {...commonRoutes.TOS_HOST},
                                {...commonRoutes.RESET_PASSWORD},
                                {path: '*', element: <NotFound />}
                            ]
                        }
                    ]
                }
            ]
        }
    ],
    {
        basename: '/'
    }
)
export default router
