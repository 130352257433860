import {AlertTypeE} from '@/features/authentication/types'
import {useRootStore} from '@/store'
import {selectUpdateCreditCardModal} from '@/store/selectors'
import {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router'
import {useTheme} from 'styled-components'
import {PROFILE_ALERTS, getVariantStyle} from './alertContent'
import {StyledAction, StyledCloseIcon, StyledContainer, StyledContent, StyledRoot} from './style'
import {ROUTE_ACTIVE_GIFTS} from '@/utilities/constants/routeNames'

type AlertType = {alertCode: AlertTypeE}

export const ProfileAlert = ({alertCode}: AlertType) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const location = useLocation()
    const {variant, admittedLocations, blockedLocations, title, description, isDismissible, action} =
        PROFILE_ALERTS[alertCode]
    const {openModal: openUpdateCreditCardModal} = useRootStore(selectUpdateCreditCardModal)
    const variantStyle = getVariantStyle(variant, theme)
    const [isVisible, setIsVisible] = useState(!sessionStorage.getItem(alertCode))
    const onBlockedLocations = !!blockedLocations && !!blockedLocations.includes(location?.pathname)
    const onAdmittedLocations = !!admittedLocations && !!admittedLocations.includes(location.pathname)

    const shouldDisplay = useMemo(() => {
        return isVisible && !onBlockedLocations && (!admittedLocations || onAdmittedLocations)
    }, [admittedLocations, isVisible, location.pathname])

    const closeHandler = () => {
        sessionStorage.setItem(alertCode, 'true')
        setIsVisible(false)
    }

    const profileAlertsAction = (alertCode: AlertTypeE) => {
        switch (alertCode) {
            case AlertTypeE.GUEST_ENTER_NEW_PAYMENT_METHOD_AMG:
                return openUpdateCreditCardModal
            default:
                return undefined
        }
    }

    if (alertCode === AlertTypeE.GUEST_CREDIT_CARD_PROBLEMS && location.pathname !== ROUTE_ACTIVE_GIFTS) return null

    return (
        <StyledRoot
            color={variantStyle.color}
            backgroundColor={variantStyle.backgroundColor}
            shouldDisplay={shouldDisplay}
        >
            <StyledContainer>
                {!!variantStyle.icon && variantStyle.icon}
                <StyledContent>
                    {!!title && <h3>{t(title)}</h3>}
                    {!!description && <div>{t(description)}</div>}
                    {!!action &&
                        (action.to ? (
                            <StyledAction color={variantStyle.color} to={action.to}>
                                {t(action.name)}
                            </StyledAction>
                        ) : (
                            <StyledAction color={variantStyle.color} as="div" onClick={profileAlertsAction(alertCode)}>
                                {t(action.name)}
                            </StyledAction>
                        ))}
                </StyledContent>
                {isDismissible && <StyledCloseIcon fill={variantStyle.closeIconColor} onClick={closeHandler} />}
            </StyledContainer>
        </StyledRoot>
    )
}
