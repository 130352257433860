import axios from '@/clientAPI.ts'
import {MediaPackage, MediaPackageCreate, MediaPackageUpdate} from './types'

export const MEDIA_PACKAGES_API = {
    getMyMediaPackages: async (): Promise<MediaPackage[]> => {
        const url = '/media-packages'
        const {data} = await axios.get(url)
        return data
    },
    getMyMediaPackage: async ({id}: {id: number}): Promise<MediaPackage> => {
        const url = `/media-packages/${id}`
        const {data} = await axios.get(url)
        return data
    },
    createMediaPackage: async (body: MediaPackageCreate) => {
        const url = '/media-packages'
        return await axios.post(url, {...body})
    },
    updateMediaPackage: async (body: MediaPackageUpdate) => {
        const {id, ...rest} = body
        const url = `/media-packages/${id}`
        return await axios.patch(url, rest)
    },
    removeMediaPackage: async (id: number) => {
        const {data} = await axios.delete(`/media-packages/${id}`)
        return data
    },
    unlockMediaPackage: async ({hostId, mediaPackageId}: {hostId: number; mediaPackageId: number}) => {
        const {data} = await axios.post(`/rookies/${hostId}/media-packages/${mediaPackageId}/unlock`)
        return data
    }
}
