import styled, {css} from 'styled-components'

type StyledContainerProps = {
    fullWidth: boolean
    hasContainer: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>`
    ${({fullWidth, hasContainer, theme: {spacing, mediaQueries}}) => css`
        width: 100%;
        padding: ${hasContainer ? `${spacing * 1.5}px` : 0};
        max-width: ${fullWidth ? '100%' : '1980px'};
        margin: 0 auto;
        ${mediaQueries.m} {
            padding: ${hasContainer ? `0 ${spacing * 2}px` : 0};
        }
    `}
`
