import styled, {css} from 'styled-components'
import {palette as themePalette} from '../../../../theme/palette'
import {GoBackButtonIcon, ShareButtonIcon} from '@/assets/icons/icons'

export const StyledHostProfileWrapper = styled.div`
    ${css`
        all: unset;
    `}
`

export const StyledAvatarWrapper = styled.div`
    ${({theme: {mediaQueries}}) => css`
        position: relative;
        width: '100%';
        height: 336px;
        ${mediaQueries.m} {
            height: 664px;
        }
    `}
`
export const StyledAvatar = styled.img`
    ${({theme: {mediaQueries}}) => css`
        height: 100%;
        width: 100%;
        object-fit: cover;
        ${mediaQueries.m} {
            object-fit: contain;
        }
    `}
`

export const StyledContainer = styled.div`
    ${({theme: {mediaQueries}}) => css`
        max-width: 375px;

        width: 100%;
        margin: 0 auto;
        ${mediaQueries.m} {
            max-width: 600px;
        }
    `}
`
export const StyledStickContainer = styled.div`
    ${({theme: {palette}}) => css`
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1000;
        background: ${palette.neutral.white};
        box-shadow:
            8px 0px 0px 0px rgb(255, 255, 255),
            -8px 0px 0px 0px rgb(255, 255, 255);
    `}
`
export const StyledConnectContainer = styled.div`
    ${({theme: {mediaQueries, spacing, typography, palette}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: ${spacing * 4}px ${spacing * 2}px ${spacing * 1.5}px;
        gap: ${spacing}px;
        & .connectNowButton {
            ${typography.textLg}
            width: min-content;
            padding: 0;
            text-wrap: nowrap;
            color: ${palette.neutral.white};
            padding: ${spacing / 2}px ${spacing * 2}px;
            ${mediaQueries.m} {
                ${typography.text2Xl}
                padding: ${spacing}px ${spacing * 3}px;
            }
        }
        ${mediaQueries.m} {
            padding: ${spacing * 4}px 0px ${spacing * 2.5}px;
        }
    `}
`
export const StyledFullName = styled.h1`
    ${({theme: {typography, mediaQueries, palette}}) => css`
        ${typography.text3Xl}
        color: ${palette.primary[900]};
        font-weight: 700;
        line-height: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 4.5em;
        ${mediaQueries.m} {
            ${typography.displayMd}
        }
    `}
`

export const ContainerRow = styled.div`
    ${({theme: {mediaQueries, spacing}}) => css`
        display: flex;
        padding: 0 ${spacing * 2}px;
        & > div {
            flex: 1;
        }
        ${mediaQueries.m} {
            padding: 0;
        }
    `}
`
export const StyledHeartSection = styled(ContainerRow)`
    ${({onClick, theme: {typography, mediaQueries, spacing}}) => css`
        cursor: ${onClick ? 'pointer' : 'default'};
        gap: ${spacing}px;
        align-items: center;
        width: min-content;
        & * {
            cursor: ${onClick ? 'pointer' : 'default'};
        }
        & span {
            ${typography.textMd}
            display: flex;
            align-items: center;
            color: #212651;
            font-weight: 700;
            ${mediaQueries.m} {
                ${typography.textXXl}
            }
        }
    `}
`
export const StyledHeartButton = styled.span`
    ${({onClick}) => css`
        cursor: ${onClick ? 'pointer' : 'default'};
    `}
`
export const StyledInformSection = styled(ContainerRow)`
    ${({theme: {mediaQueries, spacing}}) => css`
        gap: ${spacing * 2}px;
        flex-direction: column;
        padding: ${spacing * 2}px 0 ${spacing * 3}px;
        ${mediaQueries.m} {
            padding: ${spacing * 2.5}px 0 ${spacing * 4}px;
        }
    `}
`
export const StyledInformSectionRow = styled(ContainerRow)`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
    `}
`
export const StyledInformSectionRowTitle = styled.h3`
    ${({theme: {mediaQueries, typography}}) => css`
        color: #212651;
        font-weight: 700;
        letter-spacing: -0.3px;
        ${typography.textMd}
        ${mediaQueries.m} {
            ${typography.textXXl}
        }
    `}
`
export const StyledInformSectionRowContent = styled.div`
    ${({theme: {spacing, typography, mediaQueries}}) => css`
        display: flex;
        gap: ${spacing}px;
        color: rgba(44, 47, 73, 0.9);
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        flex-wrap: wrap;
        ${mediaQueries.m} {
            ${typography.textMd}
        }
    `}
`
export const StyledFavoriteItem = styled.div<{color?: string}>`
    ${({
        color = themePalette.light.neutral.black,
        theme: {mediaQueries, typography, spacing, flexUtils, palette}
    }) => css`
        color: ${color};
        ${flexUtils.centered}
        padding: ${spacing / 2}px ${spacing}px;
        width: min-content;
        min-width: min-content;
        border-radius: 100px;
        border: 0.5px solid #f2f2f2;
        background: ${palette.neutral.white};
        box-shadow: 0px 1px 8px 0px rgba(26, 8, 105, 0.25);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        cursor: default;

        ${mediaQueries.m} {
            ${typography.textXl}
            font-weight: 500;

            letter-spacing: -0.3px;
        }
    `}
`
export const styledConnectNowButton = css``
export const StyledGoBackButtonIcon = styled(GoBackButtonIcon)`
    ${({theme: {mediaQueries}}) => css`
        height: 32px;
        width: 32px;
        position: absolute;
        top: 31px;
        left: 2%;
        cursor: pointer;
        ${mediaQueries.m} {
            height: 54px;
            width: 54px;
        }
    `}
`

export const StyledShareButton = styled(ShareButtonIcon)`
    ${({theme: {mediaQueries}}) => css`
        height: 32px;
        width: 32px;
        position: absolute;
        top: 31px;
        right: 2%;
        cursor: pointer;
        ${mediaQueries.m} {
            height: 54px;
            width: 54px;
        }
    `}
`
