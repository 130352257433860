import {MorgiIcon} from '@/assets/icons/icons'
import Checkbox from '@/components/commons/checkbox/CheckBox'
import {Avatar} from '@/components/ui/avatar/Avatar'
import {getLocalDate} from '@/dayjs'
import {useGetHost} from '@/features/guest/queries/useGetHost'
import {Subscription, SubscriptionsKeysE, SubscriptionsStatusE} from '@/features/guest/types'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {
    StyledSupportedUser,
    StyledSupportedUserContent,
    StyledSupportedUserInform,
    StyledSupportedUserInformAmount,
    StyledSupportedUserInformName,
    StyledSupportedUserLastPaymentDate,
    radioInputStyles
} from './style'
import Radio from '@/components/commons/radio/Radio'

type SupportedUserProps = {
    subscription: Subscription
    onSelect: (action?: SubscriptionsStatusE) => void
    subscriptionType?: SubscriptionsKeysE
}

export const SupportedUser: FC<SupportedUserProps> = ({subscription, onSelect, subscriptionType}) => {
    const {spacing} = useTheme()
    const {data: host} = useGetHost({filter: subscription.rookie_id.toString()})
    const {t} = useTranslation()

    return (
        <>
            <StyledSupportedUser>
                <Checkbox
                    name={String(subscription.id)}
                    id={subscription.id.toString()}
                    defaultChecked={subscription.status === SubscriptionsStatusE.Active}
                    onChange={() => onSelect()}
                />
                <Avatar
                    imageUrl={host?.avatar?.url}
                    name={host?.full_name}
                    height={60}
                    width={60}
                    radius={spacing * 2}
                />
                <StyledSupportedUserContent>
                    <StyledSupportedUserInform>
                        <StyledSupportedUserInformName> {host?.username}</StyledSupportedUserInformName>
                        <StyledSupportedUserInformAmount>
                            <MorgiIcon width={21} height={21} />
                            {subscription.amount}
                        </StyledSupportedUserInformAmount>
                    </StyledSupportedUserInform>
                    <StyledSupportedUserLastPaymentDate isFailed={!!subscription.failed_at}>
                        {!subscription.failed_at
                            ? `${t('leader_payments:active_gifting_next_donation')} ${getLocalDate(
                                  subscription.next_donation_at,
                                  'YYYY-MM-DD'
                              )}`
                            : `${t('leader_payments:failed_gifting_at')} ${getLocalDate(
                                  subscription.failed_at as string,
                                  'YYYY-MM-DD'
                              )}`}
                    </StyledSupportedUserLastPaymentDate>
                </StyledSupportedUserContent>
            </StyledSupportedUser>

            {(subscription.status === SubscriptionsStatusE.Inactive ||
                subscription.status === SubscriptionsStatusE.Cancel) && (
                <div>
                    <Radio
                        name={`${subscription.rookie_id}-subscriptions_actions`}
                        value={`${subscription.rookie_id}-${SubscriptionsStatusE.Inactive}`}
                        radioInputStyles={radioInputStyles}
                        defaultChecked={true}
                        label={t('leader_payments:subscriptions_actions:keep')}
                        onClick={() => subscriptionType && onSelect(SubscriptionsStatusE.Inactive)}
                    />
                    {subscriptionType === SubscriptionsKeysE.Errors && (
                        <Radio
                            name={`${subscription.rookie_id}-subscriptions_actions`}
                            value={`${subscription.rookie_id}-${SubscriptionsStatusE.Cancel}`}
                            defaultChecked={false}
                            radioInputStyles={radioInputStyles}
                            label={t('leader_payments:subscriptions_actions:delete')}
                            onClick={() => subscriptionType && onSelect(SubscriptionsStatusE.Cancel)}
                        />
                    )}
                </div>
            )}
        </>
    )
}
