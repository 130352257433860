export const MUTATION_KEYS = {
    create_media_package: 'create-media-package',
    update_media_package: 'update-media-package',
    remove_media_package: 'remove-media-package',
    unlock_medial_package: 'unlock-media-package'
}

export const QUERY_KEYS = {
    my_media_packages: 'my-media-packages',
    my_media_package: 'my-media-package'
}
