import {Modal} from '@/components/ui/modal/Modal'
import {FC} from 'react'
import {StyledToolTipActionContent, StyledToolTipActionsContainer, StyledToolTipImage} from './style'
import {Trans, useTranslation} from 'react-i18next'

export type ToolTipContentT = {
    title: string
    icon: () => JSX.Element
    content: string
}
type ToolTipActionsModalProps = {
    content: ToolTipContentT
    onClose: () => void
}
export const ToolTipActionsModal: FC<ToolTipActionsModalProps> = ({content, onClose}) => {
    const {t} = useTranslation()
    return (
        <Modal maxWidth="300px" title={content.title} onClose={onClose}>
            <StyledToolTipActionsContainer>
                <StyledToolTipImage>{content.icon()}</StyledToolTipImage>
                <StyledToolTipActionContent>
                    <Trans t={t} i18nKey={content.content} />
                </StyledToolTipActionContent>
            </StyledToolTipActionsContainer>
        </Modal>
    )
}
