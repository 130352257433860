import {InfoIcon} from '@/assets/icons/icons'
import styled, {css} from 'styled-components'

export const StyledHostProfileMuralList = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        padding: ${spacing * 3}px 0;
        flex: 1;
    `}
`
export const StyledFilterTabs = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
        padding: ${spacing * 3}px 0;
    `}
`
export const StyledFilterTab = styled.div<{isSelected: boolean}>`
    ${({isSelected, theme: {mediaQueries, typography}}) => css`
        position: relative;
        flex: 1;
        text-align: center;
        color: rgba(0, 6, 61, 0.6);

        font-weight: 700;
        line-height: 140%; /* 25.2px */
        cursor: pointer;
        transition: color 1s;

        ${isSelected &&
        css`
            color: #00063d;
            &:after {
                content: '';
                position: absolute;
                bottom: -4px;
                left: 0;
                width: 100%;
                height: 2px;
                background: #8649d1;
            }
        `}
        ${mediaQueries.m} {
            ${typography.textLg}
        }
    `}
`
export const StyledHostProfileMuralListHeader = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: ${spacing}px;
        border-bottom: 1.714px solid rgba(127, 130, 157, 0.2);
        margin-bottom: ${spacing}px;
    `}
`
export const StyledHostProfileMuralListHeaderTitle = styled.h3`
    ${({theme: {mediaQueries, typography}}) => css`
        color: #00063d;
        text-align: center;
        font-weight: 700;
        line-height: 140%; /* 36.4px */
        font-size: 20px;
        ${mediaQueries.m} {
            ${typography.text2Xl}
        }
    `}
`
export const StyledInfoIcon = styled(InfoIcon)`
    cursor: pointer;
`
