import styled, {css} from 'styled-components'

export const StyledInfoWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        gap: ${spacing * 1.5}px;
        display: flex;
        align-items: center;
    `}
`

export const StyledInfo = styled.div`
    ${({theme: {typography, palette}}) => css`
        color: ${palette.neutral.white};
        display: flex;
        flex-direction: column;
        & div {
            ${typography.textMd}
            font-weight: 500;
        }

        & span {
            opacity: 0.5;
        }

        & img {
            width: 30px;
            height: 30px;
        }
    `}
`

export const StyledDate = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 1.5}px;
        align-items: center;
    `}
`
