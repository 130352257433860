import {PlayButton} from '@/assets/icons/icons'
import {Media} from '@/components/ui/media/Media'
import {
    mediaStyles,
    StyledMediaWrapper,
    StyledPlayButtonWrapper,
    StyledShadow,
    StyledUnpaidContent
} from '@/features/my-moments/components/moment-item/moment-preview/style'
import {MURAL_TYPES} from '@/features/profile/services/completeProfile.schema'
import {MediaPackageContent} from '@/features/profile/services/types'
import {MediaTypeE} from '@/types'
import {FC} from 'react'
import {Splide, SplideSlide} from '@splidejs/react-splide'
import {SplideType} from '@/components/ui/profile-carousel/ProfileCarousel'

type HostProfileMuralPreviewProps = {
    muralType: MURAL_TYPES
    type?: MediaTypeE
    url?: string
    isPaid: boolean
    defaultItem?: {url: string; muralType: MURAL_TYPES}
    extraUnpaidContent?: () => JSX.Element
    mediaPackagesContents?: MediaPackageContent[]
    onChangeCurrentMediaPackageIndex?: (currentIndex: number) => void
}

const HostProfileMuralPreview: FC<HostProfileMuralPreviewProps> = ({
    muralType,
    type,
    url,
    isPaid,
    defaultItem,
    mediaPackagesContents,
    extraUnpaidContent,
    onChangeCurrentMediaPackageIndex
}) => {
    switch (muralType) {
        case MURAL_TYPES.VIDEOS:
            return (
                <StyledShadow isPaid={isPaid}>
                    {!isPaid && <StyledUnpaidContent>{extraUnpaidContent?.()}</StyledUnpaidContent>}

                    <StyledMediaWrapper>
                        {isPaid && (
                            <StyledPlayButtonWrapper>
                                <PlayButton width={70} height={70} />
                            </StyledPlayButtonWrapper>
                        )}
                        <DefaultItem defaultItem={defaultItem} enabled={!isPaid && !url}>
                            <video>
                                <source src={`${url}#t=0.001`} />
                            </video>
                        </DefaultItem>
                    </StyledMediaWrapper>
                </StyledShadow>
            )

        case MURAL_TYPES.SURPRISES:
            if (type === MediaTypeE.VIDEO) {
                return (
                    <StyledShadow isPaid={isPaid}>
                        {!isPaid && <StyledUnpaidContent>{extraUnpaidContent?.()}</StyledUnpaidContent>}
                        <StyledMediaWrapper>
                            {isPaid ? (
                                <>
                                    <StyledPlayButtonWrapper>
                                        <PlayButton width={70} height={70} />
                                    </StyledPlayButtonWrapper>
                                    <video>
                                        <source src={`${url}#t=0.001`} />
                                    </video>
                                </>
                            ) : (
                                <Media imageUrl={`${url}`} mediaStyles={mediaStyles} />
                            )}
                        </StyledMediaWrapper>
                    </StyledShadow>
                )
            } else {
                return (
                    <StyledShadow isPaid={isPaid}>
                        {!isPaid && <StyledUnpaidContent>{extraUnpaidContent?.()}</StyledUnpaidContent>}
                        <Media imageUrl={`${url}`} mediaStyles={mediaStyles} />
                    </StyledShadow>
                )
            }
        case MURAL_TYPES.MEDIA_PACKAGES:
            return (
                <StyledShadow isPaid={isPaid}>
                    {!isPaid ? (
                        <>
                            <StyledUnpaidContent>{extraUnpaidContent?.()}</StyledUnpaidContent>
                            <DefaultItem
                                enabled={!isPaid}
                                defaultItem={{
                                    url:
                                        mediaPackagesContents?.[0]?.url ||
                                        (mediaPackagesContents?.[0]?.blurred_url as string),
                                    muralType:
                                        mediaPackagesContents?.[0]?.type === 'image'
                                            ? MURAL_TYPES.PHOTOS
                                            : MURAL_TYPES.VIDEOS
                                }}
                            />
                        </>
                    ) : (
                        <Splide
                            options={{
                                arrows: false,
                                pagination: true
                            }}
                            onMove={(_: SplideType, newIndex: number) => {
                                onChangeCurrentMediaPackageIndex?.(newIndex)
                            }}
                        >
                            {mediaPackagesContents?.map(item => (
                                <SplideSlide key={item.id}>
                                    {item.type === 'image' ? (
                                        <img src={item.url as string} alt={item.url as string} />
                                    ) : (
                                        <StyledMediaWrapper>
                                            <StyledPlayButtonWrapper>
                                                <PlayButton width={70} height={70} />
                                            </StyledPlayButtonWrapper>
                                            <video>
                                                <source src={`${item.url as string}#t=0.001`} />
                                            </video>
                                        </StyledMediaWrapper>
                                    )}
                                </SplideSlide>
                            ))}
                        </Splide>
                    )}
                </StyledShadow>
            )

        default:
            return (
                <StyledShadow isPaid={isPaid}>
                    {!isPaid && <StyledUnpaidContent>{extraUnpaidContent?.()}</StyledUnpaidContent>}
                    <DefaultItem defaultItem={defaultItem} enabled={!isPaid && !url}>
                        <Media imageUrl={url} mediaStyles={mediaStyles} />
                    </DefaultItem>
                </StyledShadow>
            )
    }
}

export default HostProfileMuralPreview

const DefaultItem = ({
    children = <></>,
    defaultItem,
    enabled
}: {
    children?: JSX.Element
    defaultItem?: {url: string; muralType: MURAL_TYPES}
    enabled: boolean
}) => {
    if (!defaultItem || !enabled) return children
    switch (defaultItem.muralType) {
        case MURAL_TYPES.VIDEOS:
            return (
                <StyledMediaWrapper>
                    <video>
                        <source src={`${defaultItem?.url}#t=0.001`} />
                    </video>
                </StyledMediaWrapper>
            )
        case MURAL_TYPES.PHOTOS:
            return <Media imageUrl={`${defaultItem?.url}`} mediaStyles={mediaStyles} className="blur" />
        default:
            return children
    }
}
