import {Button} from '@/components/ui/button/Button'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useMe} from '@/features/authentication/queries/useMe'
import {FeaturedRookieType, GuestType, HostPartialType} from '@/features/authentication/types'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {FC, Fragment, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {useGetMuralOfHost} from '../../queries/useGetMuralOfHost'
import {MURAL_TYPES} from '../../services/completeProfile.schema'
import {ToolTipActionsModal} from '../host-dashboard/actions-section/components/toolTipActionsModal/ToolTipActionsModal'
import HostProfileTaxiSection from '../host-profile-taxi-section/HostProfileTaxiSection'
import {ContainerRow} from '../host-profile/style'
import HostProfileMuralItem from './components/host-profile-mural-item/HostProfileMuralItem'
import {
    StyledFilterTab,
    StyledFilterTabs,
    StyledHostProfileMuralList,
    StyledHostProfileMuralListHeader,
    StyledHostProfileMuralListHeaderTitle,
    StyledInfoIcon
} from './style'
import BigInfoIcon from '@assets/images/BigInfoCircleIcon.png'

type HostProfileMuralSectionProps = {
    host: HostPartialType
}
const FILTERS_MAP = [undefined, MURAL_TYPES.SURPRISES, MURAL_TYPES.MEDIA_PACKAGES]

const HostProfileMuralSection: FC<HostProfileMuralSectionProps> = ({host}) => {
    const {data: user} = useMe()
    const isGuest = user?.type === USER_ROLE_GUEST
    const {t} = useTranslation()
    const {breakpoints} = useTheme()
    const {width} = useWindowDimensions()
    const [isMobile, setIsMobile] = useState(width <= breakpoints.width.m)
    const limit = 10
    const [selectedTab, setSelectedTab] = useState(0)
    const {
        data,
        isLoading,
        refetch: refetchMuralList,
        isFetchingNextPage,
        fetchNextPage,
        isEmptyList
    } = useGetMuralOfHost({hostID: isGuest ? String(host.id) : undefined, limit, mural_type: FILTERS_MAP[selectedTab]})
    const guest = isGuest ? (user as GuestType) : null
    const residualElements =
        (data?.pages?.at(-1)?.total as number) - (data?.pages?.at(-1)?.current_page as number) * limit || limit
    const muralList = data && data?.pages.flatMap(page => page.data)
    const [openToolTipModal, setOpenToolTipModal] = useState(false)
    const surprisesCount = host.type_attributes?.surprises_count
    const mediaPackagesCount = host.type_attributes?.media_packages_count
    const defaultItem = muralList?.find(
        item => item.url && item.mural_type === (MURAL_TYPES.PHOTOS || MURAL_TYPES.VIDEOS)
    )

    const tabs = [
        surprisesCount || mediaPackagesCount ? {id: 0, title: t('common:see_all')} : undefined,
        surprisesCount ? {id: 1, title: t('rookie_profile_page:surprises_tab', {COUNTER: surprisesCount})} : undefined,
        mediaPackagesCount
            ? {id: 2, title: t('rookie_profile_page:packages_tab', {COUNTER: mediaPackagesCount})}
            : undefined
    ].filter((tab): tab is {id: number; title: string} => Boolean(tab))

    useEffect(() => {
        setIsMobile(width <= breakpoints.width.m)
    }, [width])
    const wallInserts = [
        {
            index: 2,
            content: host.type_attributes?.featured_rookies?.length ? (
                <HostProfileTaxiSection
                    featuredRookie={host.type_attributes.featured_rookies as FeaturedRookieType[]}
                />
            ) : null
        }
    ]
    if (isEmptyList && !isLoading) return null

    return (
        <ContainerRow>
            {isLoading && <Spinner />}
            <StyledHostProfileMuralList>
                <StyledHostProfileMuralListHeader>
                    <StyledHostProfileMuralListHeaderTitle>
                        {t('rookie_profile_page:mural')}
                    </StyledHostProfileMuralListHeaderTitle>
                    <StyledInfoIcon
                        fill="#00063D80"
                        width={isMobile ? 24 : 30}
                        height={isMobile ? 24 : 30}
                        onClick={() => {
                            setOpenToolTipModal(true)
                        }}
                    />
                </StyledHostProfileMuralListHeader>
                {!!tabs.length && (
                    <StyledFilterTabs>
                        {tabs.map(tab => (
                            <StyledFilterTab
                                key={tab.id}
                                isSelected={selectedTab === tab.id}
                                onClick={() => {
                                    setSelectedTab(tab.id)
                                }}
                            >
                                {tab.title}
                            </StyledFilterTab>
                        ))}
                    </StyledFilterTabs>
                )}

                {muralList?.length &&
                    muralList.map((item, idx) => {
                        const extraContent = wallInserts.find(item => item.index === idx)
                        return (
                            <Fragment key={item.id}>
                                {extraContent?.content}
                                <HostProfileMuralItem
                                    item={item}
                                    host={host}
                                    refetchMuralList={refetchMuralList}
                                    guestsBalance={isGuest ? guest?.type_attributes.micro_morgi_balance : undefined}
                                    defaultItem={
                                        defaultItem
                                            ? {url: defaultItem.url as string, muralType: defaultItem.mural_type}
                                            : undefined
                                    }
                                />
                            </Fragment>
                        )
                    })}
                {residualElements > 0 &&
                    (isFetchingNextPage ? (
                        <Spinner center={false} inline={true} overlay={false} withMargin={false} />
                    ) : (
                        <Button
                            onClick={() => {
                                fetchNextPage()
                            }}
                        >
                            {t('rookie_profile_page:see_more_content', {
                                COUNTER: residualElements < limit ? residualElements : limit
                            })}
                        </Button>
                    ))}
            </StyledHostProfileMuralList>
            {openToolTipModal && (
                <ToolTipActionsModal
                    content={{
                        title: t('rookie_profile_preview:host_surprises:what_get_title'),
                        content: t('rookie_profile_preview:host_surprises:what_get_text'),
                        icon: () => <img src={BigInfoIcon} />
                    }}
                    onClose={() => {
                        setOpenToolTipModal(false)
                    }}
                />
            )}
        </ContainerRow>
    )
}
export default HostProfileMuralSection
