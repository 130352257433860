import {Button} from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'

export const StyledCtaSection = styled.div`
    ${({theme: {spacing}}) => css`
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
    `}
`
export const StyledActionBtnWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: ${spacing}px 0;
        gap: ${spacing * 4}px;
    `}
`
export const StyledActionBtn = styled.span`
    ${({theme: {palette, typography, mediaQueries}}) => css`
        color: ${palette.neutral.black};
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
        text-transform: capitalize;
        cursor: pointer;
        ${mediaQueries.m} {
            ${typography.textXXl}
        }
    `}
`
export const StyledShare = styled.div`
    ${({theme: {palette}}) => css`
        border: 1px solid ${palette.neutral.black};
        cursor: pointer;
        height: 60px;
        display: flex;
        max-width: 60px;
        min-width: 60px;
        align-items: center;
        border-radius: 100px;
        justify-content: center;
    `}
`
export const StyledResumeBtnWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
    `}
`
export const StyledResumeBtn = styled(Button)`
    ${({theme: {mediaQueries, typography}}) => css`
        ${typography.textSm}
        ${mediaQueries.m} {
            ${typography.textMd}
        }
    `}
`
export const StyledName = styled.span`
    display: block !important;
    overflow: hidden;
    text-overflow: ellipsis;
`
export const StyledReminderText = styled.span`
    ${({theme: {palette}}) => css`
        color: ${palette.primary[900]};
        margin: 0;
        opacity: 0.5;
        text-align: center;
    `}
`
