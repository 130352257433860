import styled, {css} from 'styled-components'

export const StyledActionButton = styled.span`
    ${({theme: {mediaQueries, typography, spacing, palette}}) => css`
        color: ${palette.neutral.black};
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
        text-transform: capitalize;
        padding-bottom: ${spacing}px;
        margin: 0 auto;
        cursor: pointer;
        ${mediaQueries.m} {
            ${typography.textXXl}
        }
    `}
`
