import {Button} from '@/components/ui/button/Button'
import {Popover} from '@/components/ui/popover/Popover'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledWrapper = styled.div`
    ${() => css`
        width: 100%;
        height: 100%;
        border-radius: 10px;

        [data-radix-popper-content-wrapper] {
            position: absolute !important;
        }
    `}
`

export const StyledItemIcon = styled.div`
    ${({theme: {mediaQueries}}) => css`
        & > svg {
            width: 18px;
            height: 15px;
        }

        & img {
            width: 15px;
            height: 16px;
        }

        ${mediaQueries.m} {
            & > svg {
                width: 22px;
                height: 19px;
            }

            & img {
                width: 22px;
                height: 23px;
            }
        }
    `}
`

export const StyledInfoWrapper = styled.div<{isMediaPackage?: boolean}>`
    ${({isMediaPackage = false, theme: {mediaQueries, spacing, palette}}) => css`
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 0 0 ${spacing * (isMediaPackage ? 4.5 : 2)}px;
        color: ${palette.neutral.white};
        width: 100%;
        gap: ${spacing * 2}px;
        ${mediaQueries.m} {
            padding: 0 0 ${spacing * (isMediaPackage ? 4.5 : 3)}px;
            gap: ${spacing * 2.75}px;
        }
    `}
`

export const StyledHost = styled.div`
    ${({theme: {spacing, typography}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        ${typography.textSm}
        font-weight: 700;
        line-height: 100%;
        & div {
            ${typography.textLg}
            color: #212651;
            font-weight: 700;
            letter-spacing: -0.472px;
        }
    `}
`

export const StyledDate = styled.span`
    ${({theme: {typography}}) => css`
        ${typography.textSm}
        color: #6d6d6d;
        font-weight: 600;
        letter-spacing: -0.3px;
    `}
`
export const StyledDeleteButton = styled.div`
    ${({theme: {spacing, palette}}: {theme: DefaultTheme}) => css`
        filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.3));
        max-width: 112px;
        cursor: pointer;
        padding: ${spacing}px ${spacing * 2}px ${spacing}px ${spacing}px;
        background-color: ${palette.neutral.white};
        border-radius: 12px;
        color: ${palette.neutral[900]};

        display: grid;
        grid-template-columns: 0fr 1fr;
        align-items: center;
        gap: ${spacing}px;
    `}
`
export const StyledTipSection = styled.div`
    ${() => css`
        margin-left: auto;
    `}
`
export const StyledTipButton = styled(Button)`
    ${({theme: {palette}}) => css`
        display: flex;
        gap: 4px;
        border-radius: 8px;
        border: 2px solid #967ae2;
        &:not(:disabled):hover {
            background: #967ae2;
            color: ${palette.neutral.white};
        }
    `}
`
export const StyledContentWrapper = styled.div<{isFreeContent?: boolean}>`
    ${({theme: {mediaQueries}, onClick, isFreeContent = true}) => css`
        position: relative;
        width: 100%;
        cursor: ${onClick ? 'pointer' : 'default'};
        height: 343px;
        flex-shrink: 0;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
        border-radius: 46px;

        & img,
        & video,
        & > :after {
            overflow: hidden;
            border-radius: 46px;
        }
        ${!isFreeContent &&
        css`
            &::before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                background: linear-gradient(rgb(0 0 0 / 0%) 85%, rgb(0 0 0 / 70%) 100%);
                z-index: 1;
            }
        `}
        ${mediaQueries.m} {
            height: 540px;
        }
    `}
`
export const StyledExclusiveContentPanel = styled.div`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.textMd}
        cursor: pointer;
        display: flex;
        position: absolute;
        bottom: ${spacing * 3}px;
        right: ${spacing * 3}px;
        color: ${palette.neutral.white};
        font-weight: 400;
        letter-spacing: -0.472px;
        z-index: 1;
        user-select: none;
    `}
`
export const StyledTriggerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100px;
    padding-right: 2px;
    background: rgba(255, 255, 255, 0.5);
`
export const StyledPopover = styled(Popover)``
export const StyledToolTipOption = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        padding: ${spacing / 2}px 0px;
        align-items: center;
        gap: ${spacing * 1.5}px;
        align-self: stretch;
        user-select: none;
        cursor: pointer;
    `}
`
export const StyledToolTipWrapper = styled.div`
    ${({theme: {spacing, typography}}) => css`
        ${typography.textMd}
        padding: ${spacing}px ${spacing * 2}px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
        font-weight: 600;
        line-height: 140%;
    `}
`
export const StyledDeleteOption = styled.span`
    color: #ee3f42;
`
export const StyledFullNameContainer = styled.div`
    ${({theme: {spacing}, onClick}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        cursor: ${onClick ? 'pointer' : 'default'};
        & > div {
            display: flex;
            flex-direction: column;
            gap: ${spacing / 2}px;
            & > p {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    `}
`
