import {useMutation} from '@tanstack/react-query'
import {MEDIA_PACKAGES_API} from '../services/mediaPackages.http'
import {MUTATION_KEYS} from './keys'

export const useUnlockMediaPackage = (onSuccess?: () => void) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.unlock_medial_package],
        mutationFn: MEDIA_PACKAGES_API.unlockMediaPackage,
        onSuccess: () => {
            onSuccess?.()
        }
    })
}
